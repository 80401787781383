import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AdvertisementService } from '../advertisement/advertisement.service';
import { CategoryService } from '../category/category.service';
import { ValueService } from '../values/values.service';

@Component({
  selector: 'app-advertisement-list',
  templateUrl: './advertisement-list.component.html',
  styleUrls: ['./advertisement-list.component.scss']
})
export class AdvertisementListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  result: any;
  AdvertisementList: any;

  GenresList: any;
  MediaPartyList: any;
  ContentTypeList: any;
  advertisementPlacementList: any;
  genreId: any = -1;
  mediaPartyId: any = -1;
  contentType: any = -1;
  advertisementPlacement: any = -1;

  constructor(
    private router: Router,
    private advertisementService: AdvertisementService,
    private renderer: Renderer,
    private categoryService: CategoryService,
    private valueService: ValueService,
  ) { }

  ngOnInit() {
    this.getAllGenres();
    this.getAllMediaParty();
    this.getAllAdvertisementPlacements();
    this.getAllAdvertisementContentType();
    //this.getAllAdvertisements();
    const that = this.advertisementService;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        dataTablesParameters.genreId = this.genreId;
        dataTablesParameters.mediaPartyId = this.mediaPartyId;
        dataTablesParameters.contentType = this.contentType;
        dataTablesParameters.advertisementPlacement = this.advertisementPlacement;
        that.getAllAdvertisement(dataTablesParameters)
          .subscribe((resp: any) => {
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'Name', name: 'Name', title: 'Name', orderable: false },
        { data: 'AdvertisementSource', name: 'AdvertisementSource', title: 'Content Source', orderable: false },
        { data: 'AdvertisementPlacement', name: 'AdvertisementPlacement', title: 'Placement', orderable: false },
        // { data: 'DurationInSeconds', name: 'DurationInSeconds', title: 'DurationInSeconds', orderable: false },
        {
          data: 'StartOnUtc', name: 'StartOnUtc', title: 'Start Date', orderable: false, render: function (data: any) {
            var startDateDate = new Date(data * 1000).getDate();
            var startDateMonth = new Date(data * 1000).getMonth();
            var startDateYear = new Date(data * 1000).getFullYear();
            var fullDate = startDateDate + "/" + (Number(startDateMonth) + 1) + "/" + startDateYear;
            return fullDate;
          }
        },
        {
          data: 'EndOnUtc', name: 'EndOnUtc', title: 'End Date', orderable: false, render: function (data: any) {
            var endDateDate = new Date(data * 1000).getDate();
            var endDateMonth = new Date(data * 1000).getMonth();
            var endDateYear = new Date(data * 1000).getFullYear();
            var fullDate = endDateDate + "/" + (Number(endDateMonth) + 1) + "/" + endDateYear;
            return fullDate;
          }
        },
        {
          data: 'Url', title: 'Url', orderable: false, render: function (data: any, type: any, row: any, full: any) {
            if (row.AdvertisementPlacement == "Sidebar")
              return '  <a target="_blank" href="' + row.ImageUrl + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
            else
              return '  <a target="_blank" href="' + data + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
          }
        },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" AdvertisementId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute("AdvertisementId")) {
        this.router.navigate(["/manageadvertisement/" + event.target.getAttribute("AdvertisementId")]);
      }
    });

    this.reloadGrid();
  }

  getAllAdvertisements() {
    var advertisementObj = { pageIndex: 1, pageSize: 20 };
    this.advertisementService.getAllAdvertisement(advertisementObj).subscribe(
      (data: any) => {
        this.AdvertisementList = data;
        // this.BindAdvertisementGrid();
      },
      error => console.log(error)
    );
  }

  AddAdvertisement() {
    this.router.navigate(['/manageadvertisement/0']);
  }

  reloadGrid() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllGenres() {
    var genresObj = { pageIndex: 1, pageSize: 1000 };
    this.categoryService.getAllGenres(genresObj).subscribe(
      (data: any) => {
        this.GenresList = [];
        var i: number = 0;
        for (i = 0; i < data.List.length; i++) {
          this.GenresList.push({ "Id": data.List[i].Id.toString(), "Name": data.List[i].Name.toString() });
        }
      },
      error => console.log(error)
    );
  }

  getAllMediaParty() {
    var genresObj = { pageIndex: 1, pageSize: 1000 };
    this.categoryService.getAllMediaParties(genresObj).subscribe(
      (data: any) => {
        this.MediaPartyList = [];
        var i: number = 0;
        for (i = 0; i < data.List.length; i++) {
          this.MediaPartyList.push({ "Id": data.List[i].Id.toString(), "Name": data.List[i].Name.toString() });
        }
      },
      error => console.log(error)
    );
  }

  getAllAdvertisementPlacements() {
    this.valueService.getAllAdvertisementPlacements().subscribe(
      (data: any) => {
        this.advertisementPlacementList = data;
      },
      error => console.log(error)
    );
  }

  getAllAdvertisementContentType() {
    this.valueService.getAllAdvertisementContentType().subscribe(
      (data: any) => {
        this.ContentTypeList = data;
      },
      error => console.log(error)
    );
  }

  BindAdvertisementGrid() {
    // $('#AdvertisementGrid').html();
  }
}
