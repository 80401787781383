import { Component, OnInit, ElementRef, ViewChild, Renderer, TemplateRef, ContentChild } from '@angular/core';
import { DeviceService } from '../devices/devices.service';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../common/toastr.service'
import { Subject } from 'rxjs';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    @ViewChild('myInput') myInputVariable: ElementRef;

    // @ViewChild('mypopup') contentVariable: TemplateRef<any>;;
    @ViewChild('mypopup') contentVariable: ElementRef;

    DeviceList: any;
    DeviceLog: any;
    csvContent: string;
    filevalid: boolean = true;
    constructor(private router: Router, private modalService: NgbModal, private deviceService: DeviceService, private ngxToast: NGXToastrService,
        private renderer: Renderer) { }
    currentpage: any;
    importDeviceCSVSample: any = '/assets/samplecsv/Import Device Sample.csv';
    dtTrigger: Subject<any> = new Subject();
    listenFunc: Function;

    ngOnInit() {

        //this.getAllDevices();
        this.currentpage = this;

        const that = this.deviceService;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: false,
            searching: false,
            destroy: true,
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
                dataTablesParameters.pageSize = dataTablesParameters.length;
                that.getAllDevice(dataTablesParameters, '')
                    .subscribe((resp: any) => {
                        //  that.persons = resp.data;
                        callback({
                            recordsTotal: resp.TotalRecords,
                            recordsFiltered: resp.TotalRecords,
                            data: resp.List
                        });
                    });




            },
            columns: [
                { data: 'Name', name: 'Name', title: 'Name', orderable: false },
                { data: 'UniqueId', name: 'UniqueId', title: 'Unique Id', orderable: false },
                { data: 'AndroidVersion', name: 'AndroidVersion', title: 'Android Version', orderable: false },
                { data: 'IMEI1', name: 'IMEI1', title: 'IMEI1', orderable: false },
                { data: 'IMEI2', name: 'IMEI2', title: 'IMEI2', orderable: false },
                {
                    data: 'Active', name: 'Active', title: 'Status', orderable: false, render: function (data: any) {
                        if (Boolean(data)) {
                            return "Active";
                        }
                        else {
                            return "Inactive";
                        }
                    }
                },
                {
                    data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
                        return '  <a>  <i class="fa fa-pencil" deviceId="' + data + '" aria-hidden="true"></i> </a>&nbsp;<a>  <i class="fa fa-bars"  open="' + data + '" aria-hidden="true"></i> </a> ';
                        ;
                    }
                }
            ]
        };
    }

    reloadGrid() {
        console.log('ContactsComponent - ngAfterViewInit()');
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        this.listenFunc();
    }


    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.listenFunc = this.renderer.listen('document', 'click', (event) => {
            if (event.target.hasAttribute("deviceId")) {
                this.router.navigate(["/managedevice/" + event.target.getAttribute("deviceId")]);
            }
            if (event.target.hasAttribute("open")) {
                var id = event.target.getAttribute("open");

                this.open(this.contentVariable, id)
            }
        });
    }

    getAllDevices() {
        var deviceObj = { pageIndex: 1, pageSize: 20 };
        this.deviceService.getAllDevice(deviceObj, '').subscribe(
            (data: any) => {
                this.DeviceList = data;
                // this.BindAdvertisementGrid();
            },
            (error) => { console.log(error) }
        );
    }

    AddDevice() {
        this.router.navigate(['/managedevice/0']);
    }


    open(content, Id) {
        this.deviceService.getDeviceLog(Id).subscribe(
            (data: any) => {
                this.DeviceLog = data;
            },
            error => {
                console.log(error);
            }

        );
        this.modalService.open(content, { size: 'lg' });
    }

    importDevices(result) {
        result = JSON.stringify(result).replace(/(?:\\[rn])+/g, "");
        result = JSON.parse(result);
        result = JSON.stringify(result);
        this.deviceService.importDevices(result)
            .subscribe(
                (data) => {
                    console.log("File " + data);
                    this.dtTrigger.next();
                    this.ngxToast.typeSuccess("Imported successfully.");
                    this.router.navigate(['/device']);
                },
                (err) => {
                    console.log("File err " + err);
                    this.ngxToast.typeError("Technical error occured");
                    //this.ngxToast.typeError(JSON.stringify(err));
                }
            );
        this.myInputVariable.nativeElement.value = "";

    }


    //onFileLoad(fileLoadedEvent,res:any=this) {
    //    const textFromFileLoaded = fileLoadedEvent.target.result;
    //    this.csvContent = textFromFileLoaded;
    //    var csv = this.csvContent;
    //    var lines = csv.split("\n");

    //    var result = [];

    //    var headers = lines[0].split(",");

    //    for (var i = 1; i < lines.length; i++) {

    //        var obj = {};
    //        var currentline = lines[i].split(",");

    //        for (var j = 0; j < headers.length; j++) {
    //            obj[headers[j]] = currentline[j];
    //        }

    //        result.push(obj);

    //    }
    //    res.importDevices(result);
    //}

    onFileSelect(input: HTMLInputElement) {
        this.filevalid = true;
        const files = input.files;
        var filename = files[0].name;
        if (filename.split('.').pop() == 'csv') {
            var content = this.csvContent;
            if (files && files.length) {
                const fileToRead = files[0];
                const fileReader = new FileReader();

                fileReader.onload = (function (theFile, parentContext) {
                    return function (e) {
                        console.log(e.target.result);
                        const textFromFileLoaded = e.target.result;
                        this.csvContent = textFromFileLoaded;
                        var csv = this.csvContent;
                        var lines = csv.split("\n");
                        // lines.splice(lines.length - 1, 1);
                        lines = lines.filter(function (item) {
                            item = item.trim()
                            return item !== ""
                        })

                        var result = [];

                        var headers = lines[0].split(",");

                        for (var i = 1; i < lines.length; i++) {
                            var obj = {};
                            var currentline = lines[i].split(",");

                            for (var j = 0; j < headers.length; j++) {
                                obj[headers[j]] = currentline[j];
                            }

                            result.push(obj);
                        }
                        parentContext.importDevices(result);
                    };
                })(files, this);
                fileReader.readAsText(fileToRead, "UTF-8");

            }
            // window.location.reload();

        }
        else {
            this.filevalid = false;
            this.myInputVariable.nativeElement.value = "";
        }
    }
}
