import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ManageDriverGroupService } from './ManageDriverGroup.service';
import { CategoryService } from '../../category/category.service'
import { DriverService } from '../../driver/driver.service'
import { Observable } from 'rxjs/Rx';
import { debug } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
import { NGXToastrService } from '../../common/toastr.service'

@Component({
  selector: 'app-manage-driver-group',
  templateUrl: './ManageDriverGroup.html'
})
export class ManageDriverGroupComponent implements OnInit {

  DriverGroupObj: { [k: string]: any } = {};
  DriverGroupId: any;
  UtcOffSetList: any;

  MediaPartyDropdownList = [];
  MediaPartySelectedItems = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(
    private router: Router,
    private manageDriverGroupService: ManageDriverGroupService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    public datepipe: DatePipe,
    private ngxToast: NGXToastrService
  ) { }

  onItemMediaPartieSelect(item: any) {
    //console.log(item);
  }
  onMediaPartieSelectAll(items: any) {
    //console.log(items);
  }

  ngOnInit() {
    this.DriverGroupObj.Active = true;
    this.activatedRoute.params
      .subscribe((params: Params) => {
        this.DriverGroupId = parseInt(params['Id']);
      });

    this.getAllMediaPartyList();
    this.getUtcOffSetList();
    //this.getDriverGroup(this.DriverGroupId);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };


    //this.selectedItems = [{ Id: 3, Name: 'Astro' }];
  }

  getAllMediaPartyList() {
    var obj = { pageIndex: 1, pageSize: 20 };
    this.categoryService.getAllMediaParties(obj).subscribe(
      (data: any) => {
        for (let i = 0; i < data.List.length; i++) {
          this.MediaPartyDropdownList.push({ Id: data.List[i].Id, Name: data.List[i].Name });
        }
        this.dropdownList = this.MediaPartyDropdownList;
        //this.MediaPartyDropdownList = data.List;
        this.getDriverGroup(this.DriverGroupId);
      },
      error => console.log(error)
    );
  }

  getUtcOffSetList() {
    this.UtcOffSetList = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  }

  getDriverGroup(id) {
    if (id > 0) {
      this.manageDriverGroupService.getDriverGroup(id).subscribe(
        (data: any) => {
          this.DriverGroupObj = data;
          for (var i = 0; i <= this.DriverGroupObj.MediaPartyIds.length - 1; i++) {
            var objname = this.MediaPartyDropdownList.find((item) => item.Id == this.DriverGroupObj.MediaPartyIds[i])
            if (objname != null) {
              this.MediaPartySelectedItems.push({ Id: objname.Id, Name: objname.Name });
            }
          }
          this.selectedItems = this.MediaPartySelectedItems;
        },
        error => console.log(error)
      );
    }
    else {
      this.DriverGroupObj.MediaPartyIds = [];
    }
  }

  onItemSelect(item: any) {
    this.DriverGroupObj.MediaPartyIds.push(item.Id);
  }
  onSelectAll(items: any) {
    this.DriverGroupObj.MediaPartyIds.push(items.Id);
  }



  SaveDriverGroup(form) {
    this.DriverGroupObj.MediaPartyIds = [];
    this.MediaPartySelectedItems = this.selectedItems;
    for (var i = 0; i <= this.MediaPartySelectedItems.length - 1; i++) {
      var objid = this.MediaPartyDropdownList.find((item) => item.Id == this.MediaPartySelectedItems[i].Id)
      if (objid != null) {
        this.DriverGroupObj.MediaPartyIds.push(objid.Id);
      }
    }
    if (form.valid) {
      this.manageDriverGroupService.SaveDriverGroup(this.DriverGroupObj).subscribe(
        (data: any) => {
          if (data.IsSuccess) {
            this.ngxToast.typeSuccess("Record saved successfully.");
            this.router.navigate(['/drivergroup']);
          } else {
            this.ngxToast.typeError(data.Message);
          }
        },
        error => console.log(error)
      );
    }
  }

  GoBackToDriverGroup() {
    this.router.navigate(['/drivergroup']);
  }
}

