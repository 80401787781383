import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../common/AppSettings'
import { AdvertiserService } from '../advertiser/advertiser.service'


@Component({
  selector: 'app-advertiser',
  templateUrl: './advertiser-list.html'
})
export class AdvertiserListcomponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  constructor(private router: Router, private advertisementService: AdvertiserService, private renderer: Renderer) { }
  Advertiserslist: any;

  ngOnInit() {
    //  this.getAllAdvertiser();


    const that = this.advertisementService;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        that.getAllAdvertiser(dataTablesParameters)
          .subscribe((resp: any) => {
            //  that.persons = resp.data;
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'Name', name: 'Name', title: 'Name', orderable: false },
        { data: 'UtcOffSet', name: 'UtcOffSet', title: 'UtcOffSet', orderable: false },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil"  AdvertiserId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }]
    };
  }


  ngAfterViewInit(): void {
    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute("AdvertiserId")) {
        this.router.navigate(["/manageadvertiser/" + event.target.getAttribute("AdvertiserId")]);
      }
    });
  }

  getAllAdvertiser() {
    var obj = { pageIndex: 1, pageSize: 20 };
    return this.advertisementService.getAllAdvertiser(obj).subscribe(
      (data: any) => {
        this.Advertiserslist = data;
      }, error => console.log(error));
  }


  AddAdvertiser() {
    this.router.navigate(['/manageadvertiser/0']);
  }
}
