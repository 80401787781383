import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageAdvertisementService {

  constructor(private http: HttpClient) { }

  getAdvertisement(advertisementId) {
    return this.http.get(AppSettings.API_URL + 'Advertisement/' + advertisementId);
  }

  SaveAdvertisement(objAdvertisement) {
    if (objAdvertisement.Id != null && objAdvertisement.Id > 0) {
      return this.http.put(AppSettings.API_URL + 'Advertisement/' + objAdvertisement.Id, objAdvertisement);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Advertisement', objAdvertisement);
    }
  }
}
