﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DriverGroupPlayListService } from '../../driver-group/DriverGroupPlayList/DriverGroupPlayList.service';
import { ValueService } from '../../values/values.service';
import { Observable } from 'rxjs/Rx';
import { AppSettings } from '../../common/AppSettings';

@Component(
{
    selector: 'app-driver-group',
    templateUrl: 'DriverGroupPlayList.html'    
})
export class DriverGroupPlayListComponent implements OnInit {

    AdvertisementContentTypeList: any;

    constructor(private router: Router,
        private drivergroupPlayListService: DriverGroupPlayListService,
        private activatedRoute: ActivatedRoute,        
        private valueService: ValueService,        
        public datepipe: DatePipe        
    ) {
    }    

    ngOnInit() {
        this.getAllPlaylistContentType();
    }

    getAllPlaylistContentType() {
        this.valueService.getAllPlaylistContentType().subscribe(
            (data: any) => {
                this.AdvertisementContentTypeList = data;
        //        console.log(data)
            },
            error => console.log(error)
        );
    }
}