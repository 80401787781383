import { Component, OnInit, ElementRef, ViewChild, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { DriverGroupService } from './driver-group.service';
import { ValueService } from '../values/values.service';
import { NGXToastrService } from '../common/toastr.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";

@Component({
    selector: 'app-driver-group',
    templateUrl: './driver-group-list.html',
    styleUrls: ['../app.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class DriverGroupComponent implements OnInit {
    @ViewChild('popup') contentVariable1: ElementRef;
    dtOptions: DataTables.Settings = {};
    result: any;
    DriverGroupList: any;
    DriverGroupPlayList: any;
    AdvertisementContentTypeList: any;
    testid: any = 1;
    VideoList: any;
    listenFunc: Function;

    constructor(private router: Router, private driverGroupService: DriverGroupService,
        private config: NgbModalConfig, private modalService: NgbModal, private valueService: ValueService, private toastrService: NGXToastrService, private renderer: Renderer) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit() {
        this.getAllDriverGroups();

        const that = this.driverGroupService;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: false,
            searching: false,
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
                dataTablesParameters.pageSize = dataTablesParameters.length;
                that.getAllDriverGroup(dataTablesParameters)
                    .subscribe((resp: any) => {
                        callback({
                            recordsTotal: resp.TotalRecords,
                            recordsFiltered: resp.TotalRecords,
                            data: resp.List
                        });
                    });
            },
            columns: [{ data: 'Name', name: 'Name', title: 'Name', orderable: false },
            {
                data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
                    return '  <a>  <i class="fa fa-pencil" driverGroupId="' + data + '" aria-hidden="true"></i> </a> &nbsp;  <a>  <i class="fa fa-bars"  videoSidebarModel="' + data + '" aria-hidden="true"></i> </a> ';
                }
            }]
        };

    }

    ngAfterViewInit(): void {
        this.listenFunc = this.renderer.listen('document', 'click', (event) => {
            if (event.target.hasAttribute("driverGroupId")) {
                this.router.navigate(["/managedrivergroup/" + event.target.getAttribute("driverGroupId")]);
            }
            if (event.target.hasAttribute("videoSidebarModel")) {
                var id = event.target.getAttribute("videoSidebarModel");
                this.open(this.contentVariable1, id)
            }
        });
    }


    getAllDriverGroups() {
        var driverGroupObj = { pageIndex: 1, pageSize: 20 };
        this.driverGroupService.getAllDriverGroup(driverGroupObj).subscribe(
            (data: any) => {
                this.DriverGroupList = data;
            },
            error => console.log(error)
        );
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.listenFunc();
    }


    AddDriverGroup() {
        this.router.navigate(['/managedrivergroup/0']);
    }

    showForCreate() {
        this.router.navigate(['/managedrivergroup/0']);
    }

    open(content, Id) {
        this.driverGroupService.getDriverGroupPlaylist(Id).subscribe(
            (data: any) => {
                this.DriverGroupPlayList = data;
            },
            error => console.log(error)
        );
        this.GetAdvertisementContentTypeList();
        this.modalService.open(content, { size: 'lg' });
    }

    removeVideoList(advertisementContentType) {
        var index = this.DriverGroupPlayList.VideoList.findIndex(x => x.ContentType == advertisementContentType);
        this.DriverGroupPlayList.VideoList.splice(index, 1);
        for (var i = 1; i <= this.DriverGroupPlayList.VideoList.length; i++) {
            this.DriverGroupPlayList.VideoList[i - 1].Order = i
        }
    }

    removeSidebarList(advertisementContentType) {
        var index = this.DriverGroupPlayList.SidebarList.findIndex(x => x.ContentType == advertisementContentType);
        this.DriverGroupPlayList.SidebarList.splice(index, 1);
        for (var i = 1; i <= this.DriverGroupPlayList.SidebarList.length; i++) {
            this.DriverGroupPlayList.SidebarList[i - 1].Order = i
        }
    }

    AddVideoList() {
        this.DriverGroupPlayList.VideoList.push({
            "ContentType": 3,
            "Order": (this.DriverGroupPlayList.VideoList != undefined ? this.DriverGroupPlayList.VideoList.length + 1 : 1)
        });

    }
    AddSidebarList() {
        this.DriverGroupPlayList.SidebarList.push({
            "ContentType": 3,
            "Order": (this.DriverGroupPlayList.SidebarList != undefined ? this.DriverGroupPlayList.SidebarList.length + 1 : 1)
        });

    }
    UpdateDriverGroupPlayList(Id) {
        // if (form.valid) {
        if (this.DriverGroupPlayList.VideoList == undefined || this.DriverGroupPlayList.VideoList <= 0) {
            this.toastrService.typeError('Please add at least one Video');
        }
        else if (this.DriverGroupPlayList.SidebarList == undefined || this.DriverGroupPlayList.SidebarList <= 0) {
            this.toastrService.typeError('Please add at least one Side bar');
        }
        else {

            this.driverGroupService.updateDriverGroupPlayList({
                "VideoList": this.DriverGroupPlayList.VideoList,
                "SidebarList": this.DriverGroupPlayList.SidebarList
            }, Id).subscribe(
                (data: any) => {
                    this.router.navigate(['/drivergroup']);
                    this.modalService.dismissAll();
                },
                error => console.log(error)
            );
        }
        //}
    }

    GetAdvertisementContentTypeList() {
        this.valueService.getAllPlaylistContentType().subscribe((data: any) => {
            this.AdvertisementContentTypeList = data;
            console.log("test");
        })
    }
}

