import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../category/category.service';
import { Subject } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { Router } from '@angular/router';
import { DriverService } from '../driver/driver.service';
declare let L;
declare let $;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  MediaPartyList: any;
  mediaPartyId: any = -1;

  dtVideoOptions: DataTables.Settings = {};
  dtVideoTrigger: Subject<any> = new Subject();
  dtSideBarOptions: DataTables.Settings = {};
  dtSideBarTrigger: Subject<any> = new Subject();

  driverList: any;

  constructor(
    private categoryService: CategoryService,
    private service: DashboardService,
    private driverService: DriverService,
  ) { }

  ngOnInit() {
    this.getDrivers();
    this.getAllMediaParty();
    this.getVideoGrid();
    this.getSideBarGrid();
  }

  ngAfterViewInit(): void {
    this.reloadGrid();
  }

  getVideoGrid() {
    const that = this.service;

    this.dtVideoOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        dataTablesParameters.mediaPartyId = this.mediaPartyId;
        dataTablesParameters.expired = true;
        that.getVideo(dataTablesParameters)
          .subscribe((resp: any) => {
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'Name', name: 'Name', title: 'Video List', orderable: false },
        { data: 'ContentType', name: 'ContentType', title: 'Content Type', orderable: false },
        { data: 'AdvertisementPlacement', name: 'AdvertisementPlacement', title: 'Placement', orderable: false },
        { data: 'DurationInSeconds', name: 'DurationInSeconds', title: 'DurationInSeconds', orderable: false },
        {
          data: 'StartOnUtc', name: 'StartOnUtc', title: 'Start Date', orderable: false, render: function (data: any) {
            var startDateDate = new Date(data * 1000).getDate();
            var startDateMonth = new Date(data * 1000).getMonth();
            var startDateYear = new Date(data * 1000).getFullYear();
            var fullDate = startDateDate + "/" + (Number(startDateMonth) + 1) + "/" + startDateYear;
            return fullDate;
          }
        },
        {
          data: 'EndOnUtc', name: 'EndOnUtc', title: 'End Date', orderable: false, render: function (data: any) {
            var endDateDate = new Date(data * 1000).getDate();
            var endDateMonth = new Date(data * 1000).getMonth();
            var endDateYear = new Date(data * 1000).getFullYear();
            var fullDate = endDateDate + "/" + (Number(endDateMonth) + 1) + "/" + endDateYear;
            return fullDate;
          }
        },
        {
          data: 'Url', title: 'Url', orderable: false, render: function (data: any, type: any, row: any, full: any) {
            if (row.AdvertisementPlacement == "Sidebar")
              return '  <a target="_blank" href="' + row.ImageUrl + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
            else
              return '  <a target="_blank" href="' + data + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
          }
        },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" AdvertisementId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  getSideBarGrid() {
    const that = this.service;

    this.dtSideBarOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        dataTablesParameters.mediaPartyId = this.mediaPartyId;
        dataTablesParameters.expired = true;
        that.getSidebar(dataTablesParameters)
          .subscribe((resp: any) => {
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'Name', name: 'Name', title: 'Side Bar List', orderable: false },
        { data: 'ContentType', name: 'ContentType', title: 'Content Type', orderable: false },
        { data: 'AdvertisementPlacement', name: 'AdvertisementPlacement', title: 'Placement', orderable: false },
        { data: 'DurationInSeconds', name: 'DurationInSeconds', title: 'DurationInSeconds', orderable: false },
        {
          data: 'StartOnUtc', name: 'StartOnUtc', title: 'Start Date', orderable: false, render: function (data: any) {
            var startDateDate = new Date(data * 1000).getDate();
            var startDateMonth = new Date(data * 1000).getMonth();
            var startDateYear = new Date(data * 1000).getFullYear();
            var fullDate = startDateDate + "/" + (Number(startDateMonth) + 1) + "/" + startDateYear;
            return fullDate;
          }
        },
        {
          data: 'EndOnUtc', name: 'EndOnUtc', title: 'End Date', orderable: false, render: function (data: any) {
            var endDateDate = new Date(data * 1000).getDate();
            var endDateMonth = new Date(data * 1000).getMonth();
            var endDateYear = new Date(data * 1000).getFullYear();
            var fullDate = endDateDate + "/" + (Number(endDateMonth) + 1) + "/" + endDateYear;
            return fullDate;
          }
        },
        {
          data: 'Url', title: 'Url', orderable: false, render: function (data: any, type: any, row: any, full: any) {
            if (row.AdvertisementPlacement == "Sidebar")
              return '  <a target="_blank" href="' + row.ImageUrl + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
            else
              return '  <a target="_blank" href="' + data + '">  <i class="fa fa-external-link-square" aria-hidden="true"></i> </a> ';
          }
        },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" AdvertisementId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  getAllMediaParty() {
    var mediaObj = { pageIndex: 1, pageSize: 20 };
    this.categoryService.getAllMediaParties(mediaObj).subscribe(
      (data: any) => {
        this.MediaPartyList = data.List;
      },
      error => console.log(error)
    );
  }

  reloadGrid() {
    this.dtVideoTrigger.next();
    this.dtSideBarTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtVideoTrigger.unsubscribe();
    this.dtSideBarTrigger.unsubscribe();
    localStorage.removeItem("DriveLoginTime");
    $.connection.hub.stop();
  }

  Bindmap() {
    $("#map").replaceWith("<div id='map' style='height: 500px;'></div>");
    var map = L.map('map').setView([30.645, 60.3643], 4), myMarker;

    L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; ' + ' Contributors',
        maxZoom: 19,
        balloonOptions: {
          autoPan: true,
          autoClose: false,
          closeButton: true,
          offset: [1, -8]
        },
      }).addTo(map);

    L.Map.extend({
      openPopup: function (popup) {
        this._popup = popup;
        return this.addLayer(popup).fire('popupopen', {
          popup: this._popup
        });
      }
    });

    var greenIcon = L.icon({
      // iconUrl: 'http://joshuafrazier.info/images/firefox.svg',
      iconUrl: '../../assets/img/mapMarker/CarIco2.png',
      iconSize: [50, 50], // size of the icon
      //iconAnchor: [25, 47], // point of the icon which will correspond to marker's location
      //popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
    });

    var markers = []

    function createMarker(coords, popupContent, id) {
      var id
      if (markers.length < 1) id = 0
      else id = markers[markers.length - 1]._id + 1
      popupContent =
        '<p>Some Infomation</p></br>' +
        '<p>test</p></br>' +
        '<button onclick="clearMarker(' + id + ')">Clear Marker</button>';
      myMarker = L.marker(coords, {
        icon: greenIcon
      });
      myMarker = L.marker(coords);
      myMarker._id = id;
      var myPopup = myMarker.bindPopup(popupContent, {
        closeButton: true
      });
      map.addLayer(myMarker);
      markers.push(myMarker);
    }

    function clearMarker(id) {
      var new_markers = [];
      var driverCount = 0
      markers.forEach(function (marker) {
        driverCount = driverCount + 1;
        $('#DriverCount').html(driverCount);
        if (marker._id == id) {
          map.removeLayer(marker);
        } else {
          new_markers.push(marker);
        }
      })
      markers = new_markers;

    }

    var driverLst = this.driverList;

    createMarker([3.1212508, 101.6790595], 'driverName', 1);
    createMarker([23.037801, 72.5056423], 'driverName', 2);
    function updatemarker() {
      var strlist = localStorage.getItem("DriveLoginTime");
      var list = JSON.parse(strlist);
      if (list) {
        for (let i = 0; i < list.length; i++) {
          var driverName;
          if (driverLst != null) {
            var indx = driverLst.findIndex(x => x.Id == Number(list[i].DriverId));
            driverName = driverLst[indx].FullName;
          }
          clearMarker(Number(list[i].DriverId));
          createMarker([Number(list[i].Latitude), Number(list[i].Longitude)], driverName, Number(list[i].DriverId));
        }
      }
      setTimeout(() => {
        updatemarker();
      }, 1000);
    }
    updatemarker();

    function removeinactiveMarker() {
      var strlist = localStorage.getItem("DriveLoginTime");
      var list = JSON.parse(strlist);
      if (list) {
        for (let i = 0; i < list.length; i++) {
          var intime = new Date(list[i].inTime);
          var curDate = new Date();
          var dif = (curDate.getTime() - intime.getTime()) / 1000;
          if (Math.abs(dif) > 30) {
            clearMarker(Number(list[i].DriverId));
            list.splice(i, 1)
            localStorage.setItem("DriveLoginTime", JSON.stringify(list));
          }
        }
      }
      setTimeout(() => {
        removeinactiveMarker();
      }, 30000);
    }
    removeinactiveMarker();

    var chat = $.connection.locationHub;
    var ary = []
    chat.client.updateDriverLocation = function (obj) {
      var text = $('#message').html();
      $('#message').html(obj.Latitude + ',' + obj.Longitude + ',' + obj.DriverId);
      console.log(obj);
      var indx = ary.findIndex(x => x.DriverId == obj.DriverId);
      if (indx == -1) {
        ary.push({ Latitude: obj.Latitude, Longitude: obj.Longitude, DriverId: obj.DriverId, inTime: new Date() });
      }
      else {
        ary[indx].inTime = new Date();
      }
      localStorage.setItem("DriveLoginTime", JSON.stringify(ary));
      var driverName;
      if (driverLst != null) {
        var indx1 = driverLst.findIndex(x => x.Id == obj.DriverId);
        driverName = driverLst[indx1].FullName;
      }
      clearMarker(obj.DriverId);
      createMarker([obj.Latitude, obj.Longitude], driverName, obj.DriverId);
    };

    // Turn logging on so we can see the calls in the browser console
    $.connection.hub.logging = true;

    // Change the hub url to point to the remote server
    $.connection.hub.url = 'http://driverapi.xoomplay.co/signalr';
    $.connection.hub.start().done(function () {
      $('#send').click(function () {
         chat.server.hello($('#msg').val());
      });
    });
  }

  getDrivers() {
    var driverGroupObjCombo = {
      name: '',
      email: '',
      phoneNumber: '',
      driverGroupId: -1,
      pageIndex: 1,
      pageSize: 1000
    };
    this.driverService.getAllDriver(driverGroupObjCombo).subscribe(
      (data: any) => {
        this.driverList = data.List;
        this.Bindmap();
      }, error => console.log(error));
  }

}
