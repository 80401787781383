import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { GameService } from '../games/games.service';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-games-prize',
  templateUrl: './games-prize.component.html',
  styleUrls: ['./games-prize.component.scss']
})
export class GamesPrizeComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  prizes: any = [];

  constructor(
    private router: Router,
    private renderer: Renderer,
    private gameService: GameService,
    private sanitizer: DomSanitizer
  ) { 
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        this.gameService.getAllPrize()
          .subscribe((resp: any) => {
            let data: any = resp;
            for(let _resp of data) {
              this.prizes.push({ id: _resp.Id, data: _resp });
            }
            callback({
              recordsTotal: resp.length,
              data: resp
            });
          });
      },
      columns: [
        { data: 'Id', name: 'Question Id', title: 'Question Id', orderable: true },
        { data: 'GameId', name: 'Question Id', title: 'Game Id', orderable: true },
        { data: 'Title', name: 'Title', title: 'Title', orderable: true },
        { data: 'Description', name: 'Description', title: 'Description', orderable: true },
        { data: 'Active', name: 'Active', title: 'Active', orderable: false },
        { data: 'PromotionCode', name: 'Promotion Code', title: 'Promotion Code', orderable: false },
        { data: 'QrCode', name: 'QR Code', title: 'QR Code', orderable: false, render: function(data: any) {
          console.log(data);
          return "<img src='" + data + "' width='72' height='72'>"
        }},
        { data: 'CreatedAt', name: 'Created At', title: 'Created At', orderable: false, render: function (data: any) {
          return new Date(data).toDateString();
        }},
        { data: 'UpdatedAt', name: 'Updated At', title: 'Updated At', orderable: false, render: function(data: any) {
          return new Date(data).toDateString();
        }},
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" prizeId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  ngOnInit() {
    //this.getAllAdvertisements();
  }

  ngAfterViewInit(): void {
    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute("prizeId")) {
        this.router.navigate(["/gamesprizemanage/" + event.target.getAttribute("prizeId")]);
      }
    });

    this.reloadGrid();
  }

  addGamePrize() {
    this.router.navigate(['/gamesprizemanage/0']);
  }

  reloadGrid() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
