import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common'
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { DlDateTimePickerDateModule } from 'angular-bootstrap-datetimepicker';
import { CustomHttpInterceptor } from './helper/customeHttpIntercepter';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { ManageAdvertisementComponent } from './advertisement-list/ManageAdvertisement/ManageAdvertisement.component';
import { LayoutComponent } from './layout/layout.component'
import { DriverComponent } from './driver/driver.component';
import { ManageDriverComponent } from './driver/ManageDriver/ManageDriver.component';
import { DriverGroupComponent } from './driver-group/driver-group.component';
import { ManageDriverGroupComponent } from './driver-group/ManageDriverGroup/ManageDriverGroup.component';
import { GenreComponent } from './category/Genre/genre.component';
import { ManageGenreComponent } from './category/Genre/ManageGenre/ManageGenre.component';
import { DriverGroupPlayListComponent } from './driver-group/DriverGroupPlayList/DriverGroupPlayList.component';


import { MediaPartyComponent } from './category/MediaParty/mediaparty.component';
import { ManageMediaPartyComponent } from './category/MediaParty/ManageMediaParty/ManageMediaParty.component';
// Advertiser Component 
import { AdvertiserListcomponent } from './advertiser/advertiser.component';

import { ManageadvertiserComponent } from './advertiser/manageadvertiser/manageadvertiser.component';
import { UserProfileComponent } from './UserProfile/userProfile.component';

import { LoginService } from './login/login.service';
import { AdvertisementService } from './advertisement/advertisement.service';
import { CategoryService } from './category/category.service';
import { ValueService } from './values/values.service';

import { DriverService } from './driver/driver.service';
import { DriverGroupService } from './driver-group/driver-group.service';
import { DriverGroupPlayListService } from './driver-group/DriverGroupPlayList/DriverGroupPlayList.service';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Advertiser Service
import { AdvertiserService } from './advertiser/advertiser.service';
import { ManageAdvertiserService } from './advertiser/manageadvertiser/manageadvertiser.service';

// toaster message
import { NGXToastrService } from '../app/common/toastr.service';
import { DevicesComponent } from './inventory/devices/devices.component';
import { ManagedevicesComponent } from './inventory/devices/managedevices/managedevices.component';

import { ToastrService } from 'ngx-toastr';

//Inventorydevices

import { DeviceService} from './inventory/devices/devices.service';
import { ManageDeviceService} from './inventory/devices/managedevices/managedevices.service';
import { UserProfileService } from './UserProfile/userProfile.service';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';



// Report online Report
import { RptdrivergrouponlinehourComponent } from './Reports/rptdrivergrouponlinehour/rptdrivergrouponlinehour.component';
import { RptdrivergrouponlinehourService } from './Reports/rptdrivergrouponlinehour/rptdrivergrouponlinehour.service';
import { BatchgroupComponent } from './category/batchgroup/batchgroup.component';
import { ManagebatchgroupComponent } from './category/batchgroup/managebatchgroup/managebatchgroup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GamesQuestionComponent } from './games-question/games-question.component';
import { GamesAnswerComponent } from './games-answer/games-answer.component';
import { GamesQuestionManageComponent } from './games-question-manage/games-question-manage.component';
import { GamesAnswerManageComponent } from './games-answer-manage/games-answer-manage.component';
import { GamesPrizeComponent } from './games-prize/games-prize.component';
import { GamesPrizeManageComponent } from './games-prize-manage/games-prize-manage.component';
import { GamesComponent } from './games/games.component';
import { AdvertisementListComponent } from './advertisement-list/advertisement-list.component';
import { GamesWinnerComponent } from './games-winner/games-winner.component';
import { GamesMusicComponent } from './games-music/games-music.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdvertisementComponent,
    LayoutComponent,
    ManageAdvertisementComponent,
    DriverComponent,
    ManageDriverComponent,
    DriverGroupComponent,
    ManageDriverGroupComponent,
    ManageGenreComponent,
    GenreComponent,
    DriverGroupPlayListComponent,
    GenreComponent,
    MediaPartyComponent,
    ManageMediaPartyComponent,
    AdvertiserListcomponent,
    ManageadvertiserComponent,
    DevicesComponent,
    ManagedevicesComponent,
    UserProfileComponent,
    RptdrivergrouponlinehourComponent,
    BatchgroupComponent,
    ManagebatchgroupComponent,
    DashboardComponent,
    GamesQuestionComponent,
    GamesAnswerComponent,
    GamesQuestionManageComponent,
    GamesAnswerManageComponent,
    GamesPrizeComponent,
    GamesPrizeManageComponent,
    GamesComponent,
    AdvertisementListComponent,
    GamesWinnerComponent,
    GamesMusicComponent
  ],
  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DlDateTimePickerDateModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    AppRoutingModule,
      AppRoutingModule,
      ToastrModule.forRoot({
          timeOut: 10000,
          positionClass: 'toast-top-right',
          preventDuplicates: true
      }),
    DataTablesModule,
    //NgxDatatableModule,
      
      NgbModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      NgxSpinnerModule
  ],
  providers: [
    DatePipe
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
    , LoginService
    , AdvertisementService
    , CategoryService
    , ValueService
    , AdvertiserService
    , DriverService
    , DriverGroupService
    , DriverGroupPlayListService
      , ManageAdvertiserService,
      NGXToastrService,
      UserProfileService,
      DeviceService,
      ManageDeviceService,
      RptdrivergrouponlinehourService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
