import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../UserProfile/userProfile.service';
declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['../app.component.scss']
})
export class LayoutComponent implements OnInit {

    isAdmin = false;

    constructor(private router: Router, private userprofileService: UserProfileService) { }

    ngOnInit() {

        this.userprofileService.getUserProfile().subscribe(
            (data: any) => {
                if (data.Username == 'admin@adsmore.com' || data.Username == 'chris@adsmore.io' || data.Username == 'hello@adsmore.io')
                this.isAdmin = true;
            },
        );

        $.getScript('../../assets/js/app-sidebar.js');
        var token = localStorage.getItem('access_token')
        if (token == null) {
            this.router.navigate(['/login']);
        }
    }

    logOut() {
        // localStorage.removeItem('access_token');
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    ngOnDestroy() {
        console.log("ngOnDestroy Layout");
    }

}