import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class DriverGroupService {

  constructor(private http: HttpClient) { }

  getAllDriverGroup(driverGroupObj) {
    var driverGroupData = "?pageIndex=" + driverGroupObj.pageIndex + "&pageSize=" + driverGroupObj.pageSize;
    return this.http.get(AppSettings.API_URL + 'DriverGroup' + driverGroupData);
  }
  getDriverGroupPlaylist(Id) {
     
      return this.http.get(AppSettings.API_URL + 'DriverGroup/' + Id + '/Playlist');
  }

  updateDriverGroupPlayList(objDriverGroupPlayList,Id) {
      return this.http.put(AppSettings.API_URL + 'DriverGroup/' + Id + '/Playlist', objDriverGroupPlayList);
  }
  
}
