import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
    providedIn: 'root'
})
export class GameService {

    constructor(private http: HttpClient) { }

    getAllGames() {
        return this.http.get(AppSettings.API_URL + "games");
    }

    updateGameScoreToWin(data: any) {
        return this.http.post(AppSettings.API_URL + "games/update", data);
    }

    getAllQuestion() {
        return this.http.get(AppSettings.API_URL + "games/questions");
    }

    getQuestion(id: any) {
        return this.http.get(AppSettings.API_URL + "games/questions/" + id);
    }

    createQuestion(data: any) {
        return this.http.post(AppSettings.API_URL + "games/questions/create", data);
    }

    updateQuestion(data: any) {
        return this.http.post(AppSettings.API_URL + "games/questions/update", data);
    }

    getQuestionAnswer(id: any) {
        return this.http.get(AppSettings.API_URL + "games/questions/" + id + "/answers");
    }

    getAllAnswer() {
        return this.http.get(AppSettings.API_URL + "games/answers");
    }

    getAnswer(id: any) {
        return this.http.get(AppSettings.API_URL + "games/answers/" + id);
    }

    createAnswer(data: any) {
        return this.http.post(AppSettings.API_URL + "games/answers/create", data);
    }

    updateAnswer(data: any) {
        return this.http.post(AppSettings.API_URL + "games/answers/update", data);
    }

    getAllPrize() {
        return this.http.get(AppSettings.API_URL + "games/prizes");
    }

    getPrize(id: any) {
        return this.http.get(AppSettings.API_URL + "games/prizes/" + id);
    }

    createPrize(data: any) {
        return this.http.post(AppSettings.API_URL + "games/prizes/create", data);
    }

    updatePrize(data: any) {
        return this.http.post(AppSettings.API_URL + "games/prizes/update", data);
    }
    
    getWinner() {
        return this.http.get(AppSettings.API_URL + "games/winner");
    }

    getGameAudio() {
        return this.http.get(AppSettings.API_URL + "games/audio");
    }

    updateGameAudio(data: any) {
        console.log(data);
        return this.http.post(AppSettings.API_URL + "games/audio/update/", data);
    }
}