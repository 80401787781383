import { Component, OnInit, ElementRef, ViewChild, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { DriverService } from './driver.service';
import { DeviceService } from '../inventory/devices/devices.service';

import * as $ from "jquery";
import { NGXToastrService } from '../common/toastr.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DebugRendererFactory2 } from '@angular/core/src/view/services';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { DriverGroupService } from '../driver-group/driver-group.service';


@Component({
  selector: 'app-driver',
  templateUrl: './driver-list.html',
  styleUrls: ['../app.component.scss']
})
export class DriverComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  @ViewChild('deviceList') contentVariable: ElementRef;

  @ViewChild('myInput')
  myInputVariable: ElementRef;

  public formTemplate: FormGroup;
  dtTrigger: Subject<any> = new Subject();

  listenFunc: Function;
  result: any;
  DriverList: any;
  DeviceList: any;
  csvContent: string;
  driverId: number;
  deviceId: number;
  filevalid: boolean = true;
  importDriverCSVSample: any = '/assets/samplecsv/Import Driver Sample.csv';
  searchText: string = '';



  drivegrouplistobjcombo: any;
  drivergroupId: any = -1;
  txtName: any = "";
  txtEmail: any = "";
  txtPhoneNo: any = "";
  constructor(
    private router: Router,
    private driverService: DriverService,
    private service: NGXToastrService,
    private deviceService: DeviceService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private renderer: Renderer,
    private drivergroupserviceforcombo: DriverGroupService
  ) { }

  //ngOnInit() {
  //  this.getAllDrivers();

  //  this.formTemplate = this.fb.group({
  //      checkedboxDeviceList:this.fb.array([]),
  //    });
  //}

  ngOnInit() {
    this.getdrivegroups();
    this.formTemplate = this.fb.group({
      checkedboxDeviceList: this.fb.array([]),
    });

    const that = this.driverService;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      destroy: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        dataTablesParameters.name = this.txtName;
        dataTablesParameters.email = this.txtEmail;
        dataTablesParameters.phoneNumber = this.txtPhoneNo;
        dataTablesParameters.driverGroupId = this.drivergroupId;
        that.getAllDriver(dataTablesParameters)
          .subscribe((resp: any) => {
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'FullName', name: 'FullName', title: 'Full Name', orderable: false },
        { data: 'Email', name: 'Email', title: 'Email', orderable: false },
        { data: 'PhoneNumber', name: 'PhoneNumber', title: 'Phone Number', orderable: false },
        {
          data: "IdentityType", title: 'Identity',
          render: function (data, type, row, meta) {
            return data + ' - ' + row.IdentityNumber;
          }
        },

        {
          data: 'Device', name: 'Device Name', title: 'Device Name', orderable: false,
          render: function (data, type, row, meta) {
            return data.Name;
          }
        },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil"  DriverId="' + data + '" aria-hidden="true"></i> </a>&nbsp;  <a>  <i class="fa fa-mobile"  openModel="' + data + '" aria-hidden="true"></i> </a>  ';
          }
        },
      ]
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.listenFunc();
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.listenFunc = this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("DriverId")) {
        this.router.navigate(["/managedriver/" + event.target.getAttribute("DriverId")]);
      }

      if (event.target.hasAttribute("openModel")) {
        var id = event.target.getAttribute("openModel");
        this.OpenDeviceList(this.contentVariable, id)
      }
    });
  }

  getAllDevices() {

    var driverObj = { pageIndex: 1, pageSize: 20 };
    this.formTemplate.setControl('checkedboxDeviceList', this.fb.array([]));
    const checkedboxDeviceListArray = this.formTemplate.controls['checkedboxDeviceList'] as FormArray;
    this.deviceService.getAllDevice(driverObj, this.searchText).subscribe(

      (data: any) => {
        this.DeviceList = data.List;
        this.DeviceList.forEach((item) => {
          this.CreateandPushFormGroup(checkedboxDeviceListArray, item);
        })
      },
      error => console.log(error)
    );
  }



  CreateandPushFormGroup(checkedboxDeviceListArray, item) {
    checkedboxDeviceListArray.push(this.fb.group({
      deviceCheckcboxValue: [false],
      UniqueId: [item.UniqueId],
      AndroidVersion: [item.AndroidVersion],
      IMEI: [item.IMEI],
      Active: [item.Active],
      Id: [item.Id],
    }));
  }

  AddDriver() {
    this.router.navigate(['/managedriver/0']);
  }



  importDrivers(result) {
    result = JSON.stringify(result).replace(/(?:\\[rn])+/g, "");
    result = JSON.parse(result);
    result = JSON.stringify(result);
    this.driverService.importDrivers(result)
      .subscribe(
        (data) => {
          console.log("File " + data);
          this.getAllDrivers();
          this.service.typeSuccess("Imported successfully.");
        },
        (err) => {
          console.log("File err " + err);
          this.service.typeError("Technical error occured");
          //this.ngxToast.typeError(JSON.stringify(err));
        }
      );
    this.myInputVariable.nativeElement.value = "";
  }

  UpdateDrivertoTablet() {
    if (this.deviceId == undefined || this.deviceId <= 0) {
      this.service.typeError("Please select atleast one Device");
    }
    else {
      this.driverService.attachaDrivertoTablet(this.driverId, this.deviceId).subscribe(
        (data: any) => {

          this.driverId = 0; this.deviceId = 0;
          this.dtTrigger.next();
          this.service.typeSuccess("Device attached successfully.");
          this.modalService.dismissAll();

        },
        error => {
          console.log(error)
          this.driverId = 0; this.deviceId = 0;
          this.service.typeError("Technical error ocuured from api.");
        }
      );
    }
    //}
  }

  DeviceChecked(e, Id) {
    var result = confirm("Are you sure you want to assign this Device?");
    if (result) {
      this.deviceId = Id;
      this.UpdateDrivertoTablet(); //Logic to delete the item
    }
    else {

      this.formTemplate.setControl('checkedboxDeviceList', this.fb.array([]));
      const checkedboxDeviceListArray = this.formTemplate.controls['checkedboxDeviceList'] as FormArray;


      this.DeviceList.forEach((item) => {
        this.CreateandPushFormGroup(checkedboxDeviceListArray, item);

      })
    }
  }

  OpenDeviceList(content, Id) {
    this.driverId = Id;
    this.getAllDevices();
    this.modalService.open(content, { size: 'lg' });
  }


  getAllDrivers() {
    var driverObj = { pageIndex: 1, pageSize: 20 };
    this.driverService.getAllDriver(driverObj).subscribe(
      (data: any) => {
        this.DriverList = data.List;

        //this.service.typeSuccess("data loaded");
      },
      error => console.log(error)
    );
  }

  onFileSelect(input: HTMLInputElement) {
    this.filevalid = true;
    const files = input.files;
    var filename = files[0].name;
    if (filename.split('.').pop() == 'csv') {
      var content = this.csvContent;
      if (files && files.length) {
        const fileToRead = files[0];
        const fileReader = new FileReader();
        fileReader.onload = (function (theFile, parentContext) {
          return function (e) {
            console.log(e.target.result);
            const textFromFileLoaded = e.target.result;
            this.csvContent = textFromFileLoaded;
            var csv = this.csvContent;
            var lines = csv.split("\n");
            // lines.splice(lines.length - 1, 1);

            lines = lines.filter(function (item) {
              item = item.trim()
              return item !== ""
            })

            var result = [];
            var headers = lines[0].split(",");
            for (var i = 1; i < lines.length; i++) {
              var obj = {};
              var currentline = lines[i].split(",");

              for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
              }
              result.push(obj);
            }
            parentContext.importDrivers(result);
          };
        })(files, this);
        fileReader.readAsText(fileToRead, "UTF-8");
      }
    }
    else {
      this.filevalid = false;
      this.myInputVariable.nativeElement.value = "";
    }
  }

  getdrivegroups() {

    var driverGroupObjCombo = {
      pageIndex: 1, pageSize: 1000
    };
    this.drivergroupserviceforcombo.getAllDriverGroup(driverGroupObjCombo).subscribe(
      (data: any) => {
        this.drivegrouplistobjcombo = data.List;
      }, error => console.log(error));

  }


  reloadGrid() {
    this.dtTrigger.next();
  }

}

