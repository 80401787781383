import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ValueService {

  constructor(private http: HttpClient) { }

  getAllAdvertisementSource() {
    return this.http.get(AppSettings.API_URL + 'Value/AdvertisementSource');
  }

  getAllAdvertisementPlacements() {
    return this.http.get(AppSettings.API_URL + 'Value/AdvertisementPlacement');
  }

  getAllAdvertisementContentType() {
    return this.http.get(AppSettings.API_URL + 'Value/AdvertisementContentType');
  }

  getAllPlaylistContentType() {
    return this.http.get(AppSettings.API_URL + 'Value/PlaylistContentType');
  }

  getAllIdentityType() {
    return this.http.get(AppSettings.API_URL + 'Value/IdentityType');
  }

  getAllDriverGroup() {
    return this.http.get(AppSettings.API_URL + 'DriverGroup?pageIndex=1&pageSize=20');
  }
}
