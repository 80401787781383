import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private http: HttpClient) { }

  getAllDriver(driverObj) {
    let apiparams = new HttpParams();
    apiparams = apiparams.append('deviceName', driverObj.name);
    apiparams = apiparams.append('email', driverObj.email);
    apiparams = apiparams.append('phoneNumber', driverObj.phoneNumber);
    if (driverObj.driverGroupId != -1) {
      apiparams = apiparams.append('driverGroupId', driverObj.driverGroupId);
    }


    apiparams = apiparams.append('Pageindex', driverObj.pageIndex);
    apiparams = apiparams.append('PageSize', driverObj.pageSize);
    return this.http.get(AppSettings.API_URL + "Driver", { params: apiparams });
    // var driverData = "?pageIndex=" + driverObj.pageIndex + "&pageSize=" + driverObj.pageSize;
    // return this.http.get(AppSettings.API_URL + 'Driver' + driverData);
  }

  importDrivers(result) {
    return this.http.post(AppSettings.API_URL + 'Driver/Import', result);
  }

  attachaDrivertoTablet(driverId, deviceId) {
    return this.http.post(AppSettings.API_URL + 'Driver/' + driverId + '/Tablet', { "DeviceId": deviceId });
  }


}
