import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(private http: HttpClient) { }

    /// Get User Profile Data
    getUserProfile() {
        return this.http.get(AppSettings.API_URL + 'User');
    }

    /// Manage user profile
    ManageUserProfile(UserProfileObj) {
       return this.http.put(AppSettings.API_URL + 'ManageUserProfiless/' + UserProfileObj.Id, UserProfileObj);
    }
}