import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../category.service'
import * as $ from "jquery";



@Component(
  {
    selector: 'app-mediaparty',
    templateUrl: './mediaparty-list.html',
    styleUrls: ['../../app.component.scss']
  }
)

export class MediaPartyComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  result: any;
  MediaPartyList: any;

  constructor(private router: Router, private categoryService: CategoryService, private renderer: Renderer) { }

  ngOnInit() {
    const that = this.categoryService;
    // this.getAllMediaParties();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        that.getAllMediaParties(dataTablesParameters)
          .subscribe((resp: any) => {
            //  that.persons = resp.data;
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [{ data: 'Name', name: 'Current Campaign', title: 'Current Campaign', orderable: false },
      {
        data: 'Active', name: 'Active', title: 'Status', orderable: false, render: function (data: any) {
          if (Boolean(data)) {
            return "Active";
          }
          else {
            return "Inactive";
          }
        }
      },
      {
        data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
          return '  <a>  <i class="fa fa-pencil" mediaId="' + data + '" aria-hidden="true"></i> </a> ';
        }
      }]
    };
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("mediaId")) {
        this.router.navigate(["/managemediaparty/" + event.target.getAttribute("mediaId")]);
      }
    });
  }

  // getAllMediaParties() {
  //   var driverObj = { pageIndex: 1, pageSize: 20 };
  //   this.categoryService.getAllMediaParties(driverObj).subscribe(
  //     (data: any) => {

  //       this.MediaPartyList = data;
  //       // this.BindAdvertisementGrid();
  //     },
  //     error => console.log(error)
  //   );
  // }

  AddMediaParty() {
    this.router.navigate(['/managemediaparty/0']);
  }


}