import { Component, OnInit, Renderer } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AdvertisementService } from '../advertisement/advertisement.service';
import { CategoryService } from '../category/category.service';
import { ValueService } from '../values/values.service';
import { GameService } from '../games/games.service';

@Component({
  selector: 'app-games-question',
  templateUrl: './games-question.component.html',
  styleUrls: ['./games-question.component.scss']
})
export class GamesQuestionComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  questions = [];

  constructor(
    private router: Router,
    private gameService: GameService,
    private renderer: Renderer
  ) { }

  ngOnInit() {
    //this.getAllAdvertisements();

    this.dtOptions = {
      pagingType: 'full_numbers',
      ajax: (dataTablesParameters: any, callback) => {
        this.gameService.getAllQuestion()
          .subscribe((resp: any) => {
            let data: any = resp;
            for(let _resp of data) {
              this.questions.push({ id: _resp.Id, data: _resp });
            }
            callback({
              data: resp
            });
          });
      },
      columns: [
        { data: 'Id', name: 'Question Id', title: 'Question Id', orderable: true },
        { data: 'GameId', name: 'Game Id', title: 'Game Id', orderable: true },
        { data: 'Title', name: 'Title', title: 'Title', orderable: true },
        { data: 'CreatedAt', name: 'Created At', title: 'Created At', orderable: false, render: function (data: any) {
          return new Date(data).toDateString();
        }},
        { data: 'UpdatedAt', name: 'Updated At', title: 'Updated At', orderable: false, render: function(data: any) {
          return new Date(data).toDateString();
        }},
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" questionId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute("questionId")) {
        this.router.navigate(["/gamesquestionmanage/" + event.target.getAttribute("questionId")]);
      }
    });

    this.reloadGrid();
  }

  AddGamesQuestion() {
    this.router.navigate(['/gamesquestionmanage/0']);
  }

  reloadGrid() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
