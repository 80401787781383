import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GameService } from './games.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  answers: any = [];

  constructor(
    private router: Router,
    private renderer: Renderer,
    private gameService: GameService
  ) { }

  ngOnInit() {
    //this.getAllAdvertisements();

    this.dtOptions = {
      pagingType: 'full_numbers',
      ajax: (dataTablesParameters: any, callback) => {
        this.gameService.getAllGames()
          .subscribe((resp: any) => {
            let data: any = resp;
            console.log(resp);
            for(let _resp of data) {
              this.answers.push({ id: _resp.Id, data: _resp });
            }
            callback({
              data: resp
            });
          });
      },
      columns: [
        { data: 'Id', name: 'Game Id', title: 'Game Id', orderable: true },
        { data: 'GameName', name: 'Name', title: 'Game Id', orderable: true },
        { data: 'GameDescription', name: 'Description', title: 'Description', orderable: true },
        { data: 'GameUniqueIdentifier', name: 'Unique Identifier', title: 'Unique Identifier', orderable: false },
        { data: 'ScoreToWin', name: 'Score To Win', title: 'Score To Win', orderable: false },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a><i class="fa fa-pencil" gameId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.renderer.listenGlobal('document', 'click', (event) => {
      if (event.target.hasAttribute("gameId")) {
        let score = prompt("Update game score to win, each correct answer will equivalent to 10 points.");
        // console.log("New score is: " + score + " with id: " + event.target.getAttribute("answerId"));
        let data = {
          Id: event.target.getAttribute("gameId"),
          ScoreToWin: score
        };
        this.gameService.updateGameScoreToWin(data).subscribe(res => {
          location.reload();
        })
      }
    });

    this.reloadGrid();
  }

  reloadGrid() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
