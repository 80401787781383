import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
    providedIn: 'root'
})
export class ManageAdvertiserService {

    constructor(private http: HttpClient) { }

    getAdvertiser(AdvertiserID) {
        return this.http.get(AppSettings.API_URL + 'Advertiser/' + AdvertiserID);
    }

    SaveAdvertiser(objAdvertiser) {
        if (objAdvertiser.Id != null && objAdvertiser.Id > 0) {
            return this.http.put(AppSettings.API_URL + 'Advertiser/' + objAdvertiser.Id, objAdvertiser);
        }
        else {
            return this.http.post(AppSettings.API_URL + 'Advertiser', objAdvertiser);
        }
    }
}
