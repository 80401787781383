import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(private http: HttpClient) { }

    getAllDevice(DeviceObj,searchText ) {
        var driverData = "?pageIndex=" + DeviceObj.pageIndex + "&pageSize=" + DeviceObj.pageSize;
        return this.http.get(AppSettings.API_URL + 'Device' + driverData + '&uniqueId=' + searchText);
    }

    getDeviceLog(Id) {
        return this.http.get(AppSettings.API_URL + 'Device/' + Id + '/Log');
    }

    importDevices(result) {
           return this.http.post(AppSettings.API_URL + 'Device/Import',result);
    }
}
