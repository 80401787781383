import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
    providedIn: 'root'
})
export class AdvertisementService {

    constructor(private http: HttpClient) { }

    getAllAdvertisement(advertisementObj) {
        let apiparams = new HttpParams();
        if (advertisementObj.genreId != -1) {
            apiparams = apiparams.append('genreId', advertisementObj.genreId);
        }
        if (advertisementObj.mediaPartyId != -1) {
            apiparams = apiparams.append('mediaPartyId', advertisementObj.mediaPartyId);
        }
        if (advertisementObj.driverGroupId != -1) {
            apiparams = apiparams.append('driverGroupId', advertisementObj.driverGroupId);
        }
        if (advertisementObj.contentType != -1) {
            apiparams = apiparams.append('contentType', advertisementObj.contentType);
        }
        if (advertisementObj.advertisementPlacement != -1) {
            apiparams = apiparams.append('advertisementPlacement', advertisementObj.advertisementPlacement);
        }

        apiparams = apiparams.append('Pageindex', advertisementObj.pageIndex);
        apiparams = apiparams.append('PageSize', advertisementObj.pageSize);
        return this.http.get(AppSettings.API_URL + "Advertisement", { params: apiparams });

        // var advertisementData = "?pageIndex=" + advertisementObj.pageIndex + "&pageSize=" + advertisementObj.pageSize;
        // return this.http.get(AppSettings.API_URL + 'Advertisement' + advertisementData);
    }
}