import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GameService } from '../games/games.service';

@Component({
  selector: 'app-games-winner',
  templateUrl: './games-winner.component.html',
  styleUrls: ['./games-winner.component.scss']
})
export class GamesWinnerComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  answers: any = [];

  constructor(
    private router: Router,
    private renderer: Renderer,
    private gameService: GameService
  ) { }

  ngOnInit() {
    //this.getAllAdvertisements();

    this.dtOptions = {
      pagingType: 'full_numbers',
      ajax: (dataTablesParameters: any, callback) => {
        this.gameService.getWinner()
          .subscribe((resp: any) => {
            let data: any = resp;
            for(let _resp of data) {
              this.answers.push({ id: _resp.Id, data: _resp });
            }
            callback({
              data: resp
            });
          });
      },
      columns: [
        { data: 'Id', name: 'Winner Id', title: 'Winner Id', orderable: true },
        { data: 'PlayerName', name: 'Player Name', title: 'Player Name', orderable: true },
        { data: 'GameName', name: 'Game Name', title: 'Game Name', orderable: true },
        { data: 'Score', name: 'Score', title: 'Score', orderable: false },
        { data: 'CreatedAt', name: 'Created At', title: 'Created At', orderable: false, render: function (data: any) {
          return new Date(data).toDateString();
        }},
        { data: 'UpdatedAt', name: 'Updated At', title: 'Updated At', orderable: false, render: function(data: any) {
          return new Date(data).toDateString();
        }},
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" answerId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    this.reloadGrid();
  }

  reloadGrid() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
