import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

import { ToastrService } from 'ngx-toastr';
import { NGXToastrService } from '../common/toastr.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../app.component.scss']
})
export class LoginComponent implements OnInit {
  EmailAddress: string;
  Password: string;
  token: string;
  result: any;

  constructor(private ngxtoast: NGXToastrService, private router: Router, private loginService: LoginService, private toastr: ToastrService) {
  }

  ngOnInit() {
    localStorage.removeItem('access_token');
    // this.alerts.setMessage('Configurations saved successfully!','success');
    // this.alerts.setMessage('Please save all the changes before closing','warn');
  }

  login() {
    var userObj = { EmailAddress: this.EmailAddress, Password: this.Password };
    if (this.EmailAddress != null && this.Password != null) {
      this.loginService.login(userObj).subscribe(
        (data: any) => {
          this.result = data;
          if (data != null && data.access_token != null) {
            localStorage.setItem('access_token', data.access_token);
            this.router.navigate(['/dashboard']);
          }
          else {
            //alert('Please Try Again!!')
            this.ngxtoast.typeError("Please Try Again!!");
            //this.alerts.setMessage('Please Try Again!!', 'error');
          }
        },
        (error) => {
          console.log(error);
          //this.alerts.setMessage(error.error.error_description, 'error');
          this.ngxtoast.typeError(error.error.error_description);
          //alert(error.error.error_description)
        }
      );
    }
    else {
      this.ngxtoast.typeError("Please Enter Email Address & Password!!");
      //alert('Please Enter Email Address & Password!!');
    }
  }

  onCancel() {
    this.EmailAddress = '';
    this.Password = '';
  }

  onForgotPassword() {

  }

}

