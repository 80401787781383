﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings';

@Injectable({
    providedIn: 'root'
})
export class DriverGroupPlayListService {

    constructor(private http: HttpClient) { }

    getDriverGroupPlayListByID(DriverID) {        
        return this.http.get(AppSettings.API_URL + 'DriverGroup/' + DriverID + '/Playlist');
    }

    updateDriverGroupPlayList(DriverID, drivergroupplayListObj) {
        if (DriverID > 0 && drivergroupplayListObj != null) {
            return this.http.post(AppSettings.API_URL + 'DriverGroup/' + DriverID + '/Playlist', drivergroupplayListObj);
        }
    }
}
