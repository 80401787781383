import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ManageDeviceService } from './managedevices.service';
import { NGXToastrService } from '../../../common/toastr.service';

@Component({
  selector: 'app-managedevices',
  templateUrl: './managedevices.component.html',
  styleUrls: ['./managedevices.component.scss']
})
export class ManagedevicesComponent implements OnInit {

  DeviceId: any;

  objDevice: any = {
    Name: '',
    Active: true,
    AndroidVersion: '',
    IMEI1: '',
    IMEI2: '',
    Id: 0,
    Price: null,
    Remark: '',
    UniqueId: ''
  };

  constructor(
    private ngxToast: NGXToastrService,
    private router: Router,
    private manageDeviceService: ManageDeviceService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe((params: Params) => {
        this.DeviceId = parseInt(params['Id']);

        if (Number(this.DeviceId) > 0) {
          this.manageDeviceService.getDevice(this.DeviceId)
            .subscribe(
              (data: any) => {
                this.objDevice = data

              },
              (err) => {
                console.log(err);
              }
            )
        }

      });
  }

  GoBackToDevice() {
    this.router.navigate(['/device']);
  }

  changeStatus() {
    //console.log(this.objDevice.Active);
  }

  SaveDevice() {
    this.manageDeviceService.SaveDevice(this.objDevice)
      .subscribe(
        (data: any) => {
          if (data.IsSuccess) {
            this.ngxToast.typeSuccess("Record saved successfully.");
            this.router.navigate(['/device']);
          } else {
            this.ngxToast.typeError(data.Message);
          }

        },
        (err) => {
          console.log(err);
          this.ngxToast.typeError(JSON.stringify(err));
        }
      );
  }

}