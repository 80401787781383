import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { ManageAdvertiserService } from '../manageadvertiser/manageadvertiser.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { debug } from 'util';
import { NGXToastrService } from '../../common/toastr.service'



@Component({
    selector: 'app-manageadvertiser',
    templateUrl: './manageadvertiser.component.html',
    providers: [NGXToastrService]
})

export class ManageadvertiserComponent implements OnInit, AfterViewInit {

    AdvertiserObj: any = {
        Name: '',
        UtcOffSet: null,
    };
    AdvertiserId: any;
    UtcOffSetList: any;


    constructor(private router: Router,
        private manageadvertiserService: ManageAdvertiserService,
        private activatedRoute: ActivatedRoute,
        private render: Renderer2,
        private toastservice: NGXToastrService
    ) {

    }

    ngAfterViewInit(): void {
        var a = document.getElementById("inputName");
        a.focus();
    }

    ngOnInit() {

        this.activatedRoute.params
            .subscribe((params: Params) => {
                this.AdvertiserId = parseInt(params['Id']);
            });

        this.getUtcOffSetList();
        this.getAdvertiser(this.AdvertiserId);

    }

    getUtcOffSetList() {
        this.UtcOffSetList = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }

    getAdvertiser(id) {
        if (id > 0) {
            this.manageadvertiserService.getAdvertiser(id).subscribe(
                (data: any) => {
                    this.AdvertiserObj = data;
                },
                error => console.log(error)
            );
        }
    }

    SaveAdvertiser() {
        this.manageadvertiserService.SaveAdvertiser(this.AdvertiserObj).subscribe(
            (data: any) => {
                if (data.IsSuccess) {
                    this.toastservice.typeSuccess("Record saved successfully.");
                    this.router.navigate(['/advertiser']);
                } else {
                    this.toastservice.typeError(data.Message);
                }
            },
            (error) => {
                console.log(error)
                this.toastservice.typeError('Technical error occured.Please try again after some time.');
            }
        );
    }

    GoBackToAdvertiser() {
        this.router.navigate(['/advertiser']);
    }

}
