import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ManageDriverService } from './ManageDriver.service';
import { CategoryService } from '../../category/category.service'
import { ValueService } from '../../values/values.service';
import { DriverService } from '../../driver/driver.service'
import { Observable } from 'rxjs/Rx';
import { NGXToastrService } from '../../common/toastr.service'

@Component({
  selector: 'app-manage-driver',
  templateUrl: './ManageDriver.html'
})
export class ManageDriverComponent implements OnInit {

  DriverObj: any = {
    Id: 0,
    FullName: "",
    Email: "",
    PhoneNumber: "",
    IdentityNumber: "",
    IdentityType: "",
    VehiclePlateNumber: "",
    Active: true,
    VehicleModel: null,
    DriverGroupId: 0,
    BatchGroupId: 0,
  };
  DriverGroupList: any;
  DriverId: any;
  IdentityTypeList: any;
  batchGroupList: any;


  constructor(private router: Router,
    private managedriverService: ManageDriverService,
    private activatedRoute: ActivatedRoute,
    private valueService: ValueService,
    public datepipe: DatePipe,
    private ngxToast: NGXToastrService,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.DriverObj.DriverGroupId = null;
    //this.DriverObj = { DriverGroup: {} };
    this.activatedRoute.params
      .subscribe((params: Params) => {
        this.DriverId = parseInt(params['Id']);
        this.getDriver(this.DriverId);
      });

    this.getDriverGroup();
    this.getBatchGroup();
    this.getIdentityTypeList();
  }

  getDriver(id) {
    if (id > 0) {
      this.managedriverService.getDriver(id).subscribe(
        (data: any) => {
          this.DriverObj = data;
          this.DriverObj.DriverGroupId = this.DriverObj.DriverGroup.Id;
          this.DriverObj.BatchGroupId = this.DriverObj.BatchGroup.Id;
        },
        error => console.log(error)
      );
    }

  }

  getDriverGroup() {
    this.valueService.getAllDriverGroup().subscribe(
      (data: any) => {
        this.DriverGroupList = data.List;
      },
      error => console.log(error)
    );
  }

  getBatchGroup() {
    var driverGroupObjCombo = {
      pageIndex: 1, pageSize: 1000
    };
    this.categoryService.getAllBatchGroup(driverGroupObjCombo).subscribe(
      (data: any) => {
        this.batchGroupList = data.List;
      }, error => console.log(error));
  }

  getIdentityTypeList() {
    this.valueService.getAllIdentityType().subscribe(
      (data: any) => {
        this.IdentityTypeList = data;
      },
      error => console.log(error)
    );
  }

  SaveDriver() {
    this.DriverObj.Name = this.DriverObj.FullName;
    this.managedriverService.SaveDriver(this.DriverObj).subscribe(
      (data: any) => {
        if (data.IsSuccess) {
          this.ngxToast.typeSuccess("Record saved successfully.");
          this.router.navigate(['/driver']);
        } else {
          this.ngxToast.typeError(data.Message);
        }
      },
      error => console.log(error)
    );
  }

  GoBackToDriver() {
    this.router.navigate(['/driver']);
  }
}

