import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getSidebar(advertisementObj) {
    let apiparams = new HttpParams();
    if (advertisementObj.mediaPartyId != -1) {
      apiparams = apiparams.append('mediaPartyId', advertisementObj.mediaPartyId);
    }
    apiparams = apiparams.append('advertisementPlacement', "Sidebar");
    apiparams = apiparams.append('expired', advertisementObj.expired);

    apiparams = apiparams.append('Pageindex', advertisementObj.pageIndex);
    apiparams = apiparams.append('PageSize', advertisementObj.pageSize);
    return this.http.get(AppSettings.API_URL + "Advertisement", { params: apiparams });
  }

  getVideo(advertisementObj) {
    let apiparams = new HttpParams();
    if (advertisementObj.mediaPartyId != -1) {
      apiparams = apiparams.append('mediaPartyId', advertisementObj.mediaPartyId);
    }
    apiparams = apiparams.append('advertisementPlacement', "Video");
    apiparams = apiparams.append('expired', advertisementObj.expired);

    apiparams = apiparams.append('Pageindex', advertisementObj.pageIndex);
    apiparams = apiparams.append('PageSize', advertisementObj.pageSize);
    return this.http.get(AppSettings.API_URL + "Advertisement", { params: apiparams });
  }
}
