import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageDriverGroupService {

  constructor(private http: HttpClient) { }

  getDriverGroup(driverGroupId) {
    return this.http.get(AppSettings.API_URL + 'DriverGroup/' + driverGroupId);
  }

  SaveDriverGroup(objDriverGroup) {
    if (objDriverGroup.Id != null && objDriverGroup.Id > 0) {
      console.log(objDriverGroup.Id, objDriverGroup)
      return this.http.put(AppSettings.API_URL + 'DriverGroup/' + objDriverGroup.Id, objDriverGroup);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'DriverGroup', objDriverGroup);
    }
  }
}
