import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageDeviceService {

  constructor(private http: HttpClient) { }

  getDevice(driverId) {
    return this.http.get(AppSettings.API_URL + 'Device/' + driverId);
  }

  SaveDevice(objDevice) {
    if (objDevice.Id != null && objDevice.Id > 0) {
      return this.http.put(AppSettings.API_URL + 'Device/' + objDevice.Id, objDevice);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Device', objDevice);
    }
  }
}
