import { Component, OnInit, ViewChild, HostListener, ElementRef, Renderer, ChangeDetectionStrategy } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ManageAdvertisementService } from './ManageAdvertisement.service';
import { CategoryService } from '../../category/category.service'
import { ValueService } from '../../values/values.service';
import { AdvertiserService } from '../../advertiser/advertiser.service';
import { Observable } from 'rxjs/Rx';
import { AppSettings } from '../../common/AppSettings'
import { NGXToastrService } from '../../common/toastr.service'
import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD MMM YYYY HH:mm',
  fullPickerInput: 'DD MMM YYYY HH:mm',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'DD MMM YYYY',
  dateA11yLabel: 'DD MMM YYYY',
  monthYearA11yLabel: 'DD MMMM YYYY',


  // parseInput: 'l LT',
  // fullPickerInput: 'l LT',
  // datePickerInput: 'l',
  // timePickerInput: 'LT',
  // monthYearLabel: 'MMM YYYY',
  // dateA11yLabel: 'LL',
  // monthYearA11yLabel: 'MMMM YYYY',
};

declare var $;

@Component({
  selector: 'app-manage-advertisement',
  templateUrl: './ManageAdvertisement.html',
  styleUrls: ['./ManageAdvertisement.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // `MomentDateTimeAdapter` can be automatically provided by importing
    // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
})

export class ManageAdvertisementComponent implements OnInit {
  result: any;
  //AdvertisementObj: object = {};
  //AdvertisementObj: { [k: string]: any } = {};
  startDate = new moment();
  endDate = new moment();
  AdvertisementObj: any = {};
  MediaPartyList: any;
  ItemsMediaParties: Array<{ id: string, name: string }> = [];
  GenresList: any;
  ItemsGenres: Array<{ id: string, name: string }> = [];
  AdvertisersList: any;
  ItemsAdvertisers: Array<{ id: string, name: string }> = [];
  AdvertisementSourceList: any;
  AdvertisementId: any;
  AdvertisementPlacementList: any;
  AdvertisementContentTypeList: any;
  GenresString = '';
  MediaPartyString = '';
  userMediaParties = [];
  userGenresList = [];

  show: boolean = false;
  showMedia: boolean = false;
  showCalender: boolean = false;
  showCalenderStartOn: boolean = false;

  googleSelected: boolean = false;
  inhouseSelected: boolean = false;


  GenresDropdownList = [];
  GenresSelectedItems = [];
  MediaPartyDropdownList = [];
  MediaPartySelectedItems = [];
  dropdownSettings = {};

  constructor(private router: Router,
    private mngadvertisementService: ManageAdvertisementService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private valueService: ValueService,
    private advertiserService: AdvertiserService,
    public datepipe: DatePipe,
    private el: ElementRef, private renderer: Renderer,
    private toasetrservice: NGXToastrService
  ) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (event.target.outerHTML.indexOf('name="MediaParties"') >= 0) {
      this.showMedia = true;
    }
    else {
      this.showMedia = false;
    }


    if (event.target.outerHTML.indexOf('name="Genres"') >= 0) {
      this.show = true;
    }
    else {
      this.show = false;
    }

    if (event.target.outerHTML.indexOf('name="calendarEndOn"') >= 0) {
      this.showCalender = true;
    }
    else {
      this.showCalender = false;
    }

    if (event.target.outerHTML.indexOf('name="calendarStartOn"') >= 0) {
      this.showCalenderStartOn = true;
    }
    else {
      this.showCalenderStartOn = false;
    }
  }

  onItemGenreSelect(item: any) {
    console.log(item);
  }
  onGenreSelectAll(items: any) {
    console.log(items);
  }

  onItemMediaPartieSelect(item: any) {
    console.log(item);
  }
  onMediaPartieSelectAll(items: any) {
    console.log(items);
  }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };

    //$("#dtpStartOn").datepicker({
    //  showOn: "button",
    //  buttonImageOnly: false,
    //  buttonText: "Select date"
    //});
    //$("#dtpEndOns").datepicker({
    //  showOn: "button",
    //  buttonImageOnly: false,
    //  buttonText: "Select date"
    //});

    //$('.ui-datepicker-trigger').addClass('fa fa-calendar')
    //$(".ui-datepicker-trigger").html('');

    this.getAllMediaParty();
    this.getAllAdvertisementContentType();
    this.getAllAdvertisementPlacements();
    this.getAllAdvertisementSource();
    this.getAllAdvertiser();
    this.activatedRoute.params
      .subscribe((params: Params) => {
        this.AdvertisementId = parseInt(params['Id']);
      });
  }

  ngAfterViewInit(): void {
    var a = document.getElementById("txtinputDuration");
    a.focus();
    setTimeout(() => {
      var b = document.getElementById("txtinputName");
      b.focus();
    }, 1000);
  }

  ////////////-----------------------------MultiSelect DD------------------------------/////////////////////
  openDD() {
    this.show = true;
  }

  openDDMedia() {
    this.showMedia = true;
  }

  selectMediaParty(s) {
    this.userMediaParties.find((item) => item.id === s.id) ?
      this.userMediaParties = this.userMediaParties.filter((item) => item.id !== s.id) :
      this.userMediaParties.push(s);
  }

  deleteMediaParties(s) {
    this.userMediaParties = this.userMediaParties.filter((item) => item.id !== s.id);
  }


  isSelectedMediaParties(s: any) {
    return this.userMediaParties.findIndex((item) => item.id === s.id) > -1 ? true : false;
  }

  selectGenres(s) {
    this.userGenresList.find((item) => item.id === s.id) ?
      this.userGenresList = this.userGenresList.filter((item) => item.id !== s.id) :
      this.userGenresList.push(s);
  }

  deleteGenres(s) {
    this.userGenresList = this.userGenresList.filter((item) => item.id !== s.id);
  }

  // assignToNgModel() {
  //     this.GenresString = '';
  //     this.userGenresList.map((item) => this.GenresString += item.name + ' ');
  // }


  isSelected(s: any) {
    return this.userGenresList.findIndex((item) => item.id === s.id) > -1 ? true : false;
  }

  ////////////-----------------------------MultiSelect DD------------------------------/////////////////////

  openCalenderEndOn() {
    this.showCalender = true;
  }

  openCalenderStartOn() {
    this.showCalenderStartOn = true;
  }

  AdvertisementSourceChange(val) {
    console.log(val);
    if (val.includes("GoogleDFP")) {
      this.googleSelected = true;
    } else {

      this.googleSelected = false;
    }
    if (val.includes("InHouse")) {
      this.inhouseSelected = true;
    } else {

      this.inhouseSelected = false;
    }
    console.log(this.inhouseSelected);
    console.log(this.AdvertisementObj.AdvertisementPlacement);
  }

  ////////////-----------------------------Calling Page Load Methods------------------------------/////////////////////
  getAllMediaParty() {
    var mediaObj = { pageIndex: 1, pageSize: 20 };
    this.categoryService.getAllMediaParties(mediaObj).subscribe(
      (data: any) => {
        this.MediaPartyList = data.List;
        var i: number = 0;
        for (i = 0; i < this.MediaPartyList.length; i++) {
          this.ItemsMediaParties.push({ "id": this.MediaPartyList[i].Id.toString(), "name": this.MediaPartyList[i].Name.toString() })
        }
        this.MediaPartyDropdownList = this.ItemsMediaParties;
        this.getAllGenres();
      },
      error => console.log(error)
    );
  }

  getAllGenres() {
    var genresObj = { pageIndex: 1, pageSize: 20 };
    this.categoryService.getAllGenres(genresObj).subscribe(
      (data: any) => {
        this.GenresList = data.List;
        var i: number = 0;
        for (i = 0; i < this.GenresList.length; i++) {
          this.ItemsGenres.push({ "id": this.GenresList[i].Id.toString(), "name": this.GenresList[i].Name.toString() });
        }
        this.GenresDropdownList = this.ItemsGenres;
        this.getAdvertisement(this.AdvertisementId);
      },
      error => console.log(error)
    );
  }

  getAllAdvertisementSource() {
    this.valueService.getAllAdvertisementSource().subscribe(
      (data: any) => {
        this.AdvertisementSourceList = data;
      },
      error => console.log(error)
    );
  }

  getAllAdvertisementContentType() {
    this.valueService.getAllAdvertisementContentType().subscribe(
      (data: any) => {
        this.AdvertisementContentTypeList = data;
      },
      error => console.log(error)
    );
  }

  getAllAdvertisementPlacements() {
    this.valueService.getAllAdvertisementPlacements().subscribe(
      (data: any) => {
        this.AdvertisementPlacementList = data;
      },
      error => console.log(error)
    );
  }

  getAllAdvertiser() {
    var advertiserObj = { pageIndex: 1, pageSize: 20 };
    this.advertiserService.getAllAdvertiser(advertiserObj).subscribe(
      (data: any) => {
        this.AdvertisersList = data.List;
      }
    )
  }

  getAdvertisement(id) {
    if (id > 0) {
      this.mngadvertisementService.getAdvertisement(id).subscribe(
        (data: any) => {
          this.AdvertisementObj = data;

          var dateAdvertise = new Date(0);
          var dateAdvertise1 = new Date(0);
          dateAdvertise.setUTCSeconds(this.AdvertisementObj.StartOnUtc);
          dateAdvertise1.setUTCSeconds(this.AdvertisementObj.EndOnUtc);
          this.AdvertisementObj.StartOn = dateAdvertise;
          this.AdvertisementObj.EndOn = dateAdvertise1;
          this.startDate = dateAdvertise;
          this.endDate = dateAdvertise1;

          console.log(this.AdvertisementObj);
          if (this.AdvertisementObj.AdvertisementSource === "GoogleDFP") {
            this.googleSelected = true;
          }
          else {
            this.googleSelected = false;
          }
          if (this.AdvertisementObj.AdvertisementSource === "InHouse") {
            this.inhouseSelected = true;
          }
          else {
            this.inhouseSelected = false;
          }
          var i: number = 0;
          var temp: Array<{}> = [];
          for (i = 0; i < this.AdvertisementObj.Genres.length; i++) {
            temp.push(this.ItemsGenres.filter(items => items.name.toString() == this.AdvertisementObj.Genres[i].toString())[0]);
          }
          this.userGenresList = temp;
          this.GenresSelectedItems = this.userGenresList;

          temp = [];
          for (i = 0; i < this.AdvertisementObj.MediaParties.length; i++) {
            temp.push(this.ItemsMediaParties.filter(items => items.name.toString() == this.AdvertisementObj.MediaParties[i].toString())[0]);
          }
          this.userMediaParties = temp;
          this.MediaPartySelectedItems = this.userMediaParties;
        },
        error => console.log(error)
      );
    }
    else {
      this.AdvertisementObj.Name = "";
    }
  }
  ////////////-----------------------------Calling Page Load Methods------------------------------/////////////////////

  GoBackToAdvertisements() {
    this.router.navigate(['/advertisement']);
  }

  SaveAdvertisement(form) {
    debugger;
    this.startDate = $('#dtpStartOn').val();
    this.endDate = $('#dtpEndOns').val();
    this.userMediaParties = this.MediaPartySelectedItems;
    this.userGenresList = this.GenresSelectedItems;
    if (form.valid && this.userGenresList.length > 0 && this.userMediaParties.length > 0 && (this.AdvertisementId == 0 ? (this.AdvertisementObj.AdvertiserId > 0) : true)) {
      var type = (this.AdvertisementObj.AdvertisementPlacement.toLowerCase() == "video" ? 1 : 2);

      console.log('validated');
      if (this.AdvertisementId > 0) {
        if (type == 1 && (this.AdvertisementObj.Url == null || this.AdvertisementObj.Url == undefined)) {
          alert('You should upload file in Video File.');
          return false;
        }
      }
      else {
        var flurlupload = (<HTMLInputElement>document.getElementById('urlupload'));
        if ((type == 1 && flurlupload != null)) {
          if (flurlupload.files.length == 0) {
            alert('You should upload file in Video File.');
            return false;
          }
        }
      }

      if (this.AdvertisementId > 0) {

        if (!this.googleSelected) {
          if (type == 2 && (this.AdvertisementObj.ImageUrl == null || this.AdvertisementObj.ImageUrl == undefined) && (this.AdvertisementObj.LinkUrl == null || this.AdvertisementObj.LinkUrl == undefined)) {
            alert('You should upload file in Image File & specify Link URL.');
            return false;
          }
        }

      }
      else {
        var flimageUrlupload = (<HTMLInputElement>document.getElementById('imageUrlupload'));
        if (type == 2 && flimageUrlupload != null && (this.AdvertisementObj.LinkUrl == null || this.AdvertisementObj.LinkUrl == undefined)) {
          if (flimageUrlupload.files.length == 0) {
            alert('You should upload file in Image File & specify Link URL.');
            return false;
          }
        }
      }

      let url: any;
      let fi: any;
      var i: number = 0;
      var j: number = 0;
      var tempuserGenresList: Array<{}> = [];
      var tempuserMediaPartyList: Array<{}> = [];

      for (i = 0; i < this.userGenresList.length; i++) {
        tempuserGenresList.push(parseInt(this.userGenresList[i].id));
      }
      for (i = 0; i < this.userMediaParties.length; i++) {
        tempuserMediaPartyList.push(parseInt(this.userMediaParties[i].id));
      }

      var formData = new FormData();
      if (type == 1) {
        var filecomp = (<HTMLInputElement>document.getElementById('urlupload'));
        if (filecomp != null) {
          if ((<HTMLInputElement>document.getElementById('urlupload')).files.length > 0) {
            var files = (<HTMLInputElement>document.getElementById('urlupload')).files[0];
            formData.append(files.name, files);
          }
          else {
            this.AdvertisementObj.StartOn = this.startDate;
            this.AdvertisementObj.EndOn = this.endDate;

            this.AdvertisementObj.GenreIds = tempuserGenresList;
            this.AdvertisementObj.MediaPartyIds = tempuserMediaPartyList;
            this.AdvertisementObj.StartOnUtc = Math.floor(new Date(this.AdvertisementObj.StartOn).getTime() / 1000.0);
            this.AdvertisementObj.EndOnUtc = Math.floor(new Date(this.AdvertisementObj.EndOn).getTime() / 1000.0);
            this.AdvertisementObj.EndOn = this.datepipe.transform(this.AdvertisementObj.EndOn, 'dd MMM yyyy');
            this.AdvertisementObj.DurationInSeconds = parseInt(this.AdvertisementObj.DurationInSeconds);
            this.AdvertisementObj.AdvertiserId = parseInt(this.AdvertisementObj.AdvertiserId);
            this.mngadvertisementService.SaveAdvertisement(this.AdvertisementObj).subscribe(
              (data: any) => {

                if (data.IsSuccess) {
                  this.toasetrservice.typeSuccess("Record saved successfully.");
                  this.router.navigate(['/advertisement']);
                } else {
                  this.toasetrservice.typeError(data.Message);
                }

              },
              error => console.log(error)
            );
          }
        }
        else {
          this.AdvertisementObj.StartOn = this.startDate;
          this.AdvertisementObj.EndOn = this.endDate;

          this.AdvertisementObj.GenreIds = tempuserGenresList;
          this.AdvertisementObj.MediaPartyIds = tempuserMediaPartyList;
          this.AdvertisementObj.StartOnUtc = Math.floor(new Date(this.AdvertisementObj.StartOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOnUtc = Math.floor(new Date(this.AdvertisementObj.EndOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOn = this.datepipe.transform(this.AdvertisementObj.EndOn, 'dd MMM yyyy');
          this.AdvertisementObj.DurationInSeconds = parseInt(this.AdvertisementObj.DurationInSeconds);
          this.AdvertisementObj.AdvertiserId = parseInt(this.AdvertisementObj.AdvertiserId);
          this.mngadvertisementService.SaveAdvertisement(this.AdvertisementObj).subscribe(
            (data: any) => {
              if (data.IsSuccess) {
                this.toasetrservice.typeSuccess("Record saved successfully.");
                this.router.navigate(['/advertisement']);
              } else {
                this.toasetrservice.typeError(data.Message);
              }
            },
            error => console.log(error)
          );
        }


      }
      else {
        var fileimageUrlupload = (<HTMLInputElement>document.getElementById('imageUrlupload'));
        if (fileimageUrlupload != null) {
          if ((<HTMLInputElement>document.getElementById('imageUrlupload')).files.length > 0) {
            var files = (<HTMLInputElement>document.getElementById('imageUrlupload')).files[0];
            formData.append(files.name, files);
          }
          else {
            this.AdvertisementObj.StartOn = this.startDate;
            this.AdvertisementObj.EndOn = this.endDate;

            this.AdvertisementObj.GenreIds = tempuserGenresList;
            this.AdvertisementObj.MediaPartyIds = tempuserMediaPartyList;
            this.AdvertisementObj.StartOnUtc = Math.floor(new Date(this.AdvertisementObj.StartOn).getTime() / 1000.0);
            this.AdvertisementObj.EndOnUtc = Math.floor(new Date(this.AdvertisementObj.EndOn).getTime() / 1000.0);
            this.AdvertisementObj.EndOn = this.datepipe.transform(this.AdvertisementObj.EndOn, 'dd MMM yyyy');
            this.AdvertisementObj.DurationInSeconds = parseInt(this.AdvertisementObj.DurationInSeconds);
            this.AdvertisementObj.AdvertiserId = parseInt(this.AdvertisementObj.AdvertiserId);
            this.mngadvertisementService.SaveAdvertisement(this.AdvertisementObj).subscribe(
              (data: any) => {
                if (data.IsSuccess) {
                  this.toasetrservice.typeSuccess("Record saved successfully.");
                  this.router.navigate(['/advertisement']);
                } else {
                  this.toasetrservice.typeError(data.Message);
                }
              },
              error => console.log(error)
            );
          }
        }
        else {
          this.AdvertisementObj.StartOn = this.startDate;
          this.AdvertisementObj.EndOn = this.endDate;

          this.AdvertisementObj.GenreIds = tempuserGenresList;
          this.AdvertisementObj.MediaPartyIds = tempuserMediaPartyList;
          this.AdvertisementObj.StartOnUtc = Math.floor(new Date(this.AdvertisementObj.StartOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOnUtc = Math.floor(new Date(this.AdvertisementObj.EndOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOn = this.datepipe.transform(this.AdvertisementObj.EndOn, 'dd MMM yyyy');
          this.AdvertisementObj.DurationInSeconds = parseInt(this.AdvertisementObj.DurationInSeconds);
          this.AdvertisementObj.AdvertiserId = parseInt(this.AdvertisementObj.AdvertiserId);
          this.mngadvertisementService.SaveAdvertisement(this.AdvertisementObj).subscribe(
            (data: any) => {
              if (data.IsSuccess) {
                this.toasetrservice.typeSuccess("Record saved successfully.");
                this.router.navigate(['/advertisement']);
              } else {
                this.toasetrservice.typeError(data.Message);
              }
            },
            error => console.log(error)
          );
        }
      }
      if ((<HTMLInputElement>document.getElementById('urlupload')).files.length > 0) {
        var token = "bearer " + localStorage.getItem('access_token');
        var AdvertisementObj = this.AdvertisementObj;
        let instance: ManageAdvertisementComponent = this;
        $.ajax({
          type: "POST",
          url: AppSettings.API_URL + 'Upload/File',
          contentType: false,
          processData: false,
          data: formData,
          headers: { "Authorization": token },
          success: function (data) {

          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.log(textStatus, errorThrown);
            this.toasetrservice.typeError(errorThrown);
          }
        }).then((m) => {
          let url: any;
          url = m.Entity.Url;

          if (type == 1) {
            this.AdvertisementObj.Url = url;
            this.AdvertisementObj.ImageUrl = null;
            this.AdvertisementObj.LinkURL = null;
          }
          else {
            this.AdvertisementObj.ImageUrl = url;
            this.AdvertisementObj.Url = null;
          }
          this.AdvertisementObj.StartOn = this.startDate;
          this.AdvertisementObj.EndOn = this.endDate;

          this.AdvertisementObj.GenreIds = tempuserGenresList;
          this.AdvertisementObj.MediaPartyIds = tempuserMediaPartyList;
          this.AdvertisementObj.StartOnUtc = Math.floor(new Date(this.AdvertisementObj.StartOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOnUtc = Math.floor(new Date(this.AdvertisementObj.EndOn).getTime() / 1000.0);
          this.AdvertisementObj.EndOn = this.datepipe.transform(this.AdvertisementObj.EndOn, 'dd MMM yyyy');
          this.AdvertisementObj.DurationInSeconds = parseInt(this.AdvertisementObj.DurationInSeconds);
          this.AdvertisementObj.AdvertiserId = parseInt(this.AdvertisementObj.AdvertiserId);
          console.log(AdvertisementObj);
          this.mngadvertisementService.SaveAdvertisement(this.AdvertisementObj).subscribe(
            (data: any) => {
              this.router.navigate(['/advertisement']);
              if (data.IsSuccess) {
                this.toasetrservice.typeSuccess("Record saved successfully.");
                this.router.navigate(['/advertisement']);
              } else {
                this.toasetrservice.typeError(data.Message);
              }
            },
            error => console.log(error)
          );
        }
        );
      }
    }
  }


}
