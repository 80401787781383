import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NGXToastrService } from 'src/app/common/toastr.service';
import { BatchgroupService } from '../batchgroup.service';

@Component({
  selector: 'app-managebatchgroup',
  templateUrl: './managebatchgroup.component.html',
  styleUrls: ['./managebatchgroup.component.scss']
})
export class ManagebatchgroupComponent implements OnInit {

  BatchGroupId: any;

  objData: any = {
    inputName: '',
    Active: false
  }

  constructor(
    private router: Router,
    private service: BatchgroupService,
    private activatedRoute: ActivatedRoute,
    private ngxToast: NGXToastrService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.
      subscribe((params: Params) => {
        this.BatchGroupId = parseInt(params['Id']);
        if (this.BatchGroupId > 0) {
          this.getBatchGroup(this.BatchGroupId);
        }
      });
  }

  getBatchGroup(id) {
    this.service.getBatchGroup(id).subscribe(
      (data: any) => {
        if (data) {
          this.objData.inputName = data.Name;
          this.objData.Active = data.Active;
        }
      },
      error => console.log(error)
    );
  }

  SaveBatchGroup() {
    var obj = {
      Name: this.objData.inputName,
      Active: this.objData.Active
    }
    console.log(obj);
    this.service.SaveBatchGroup(obj).subscribe(
      (data: any) => {
        this.ngxToast.typeSuccess("Record saved successfully.");
        this.router.navigate(['/batchgroup']);
      },
      error => console.log(error)
    );
  }

  GoBackToBatchGroup() {
    this.router.navigate(['/batchgroup']);
  }

}
