import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(private http: HttpClient) { }

    getAllMediaParties(mediaObj) {
        var mediaData = "?pageIndex=" + mediaObj.pageIndex + "&pageSize=" + mediaObj.pageSize;

        let apiparams = new HttpParams();

        apiparams = apiparams.append('active', 'true');
        apiparams = apiparams.append('Pageindex', mediaObj.pageIndex);
        apiparams = apiparams.append('PageSize', mediaObj.pageSize);

        return this.http.get(AppSettings.API_URL + 'Category/MediaParty', { params: apiparams });
    }

    getAllGenres(genresObj) {

        let apiparams = new HttpParams();

        apiparams = apiparams.append('active', 'true');
        apiparams = apiparams.append('Pageindex', genresObj.pageIndex);
        apiparams = apiparams.append('PageSize', genresObj.pageSize);

        return this.http.get(AppSettings.API_URL + 'Category/Genre', { params: apiparams });

    }

    getAllBatchGroup(batchGroupObj) {

        let apiparams = new HttpParams();

        apiparams = apiparams.append('active', 'true');
        apiparams = apiparams.append('Pageindex', batchGroupObj.pageIndex);
        apiparams = apiparams.append('PageSize', batchGroupObj.pageSize);

        return this.http.get(AppSettings.API_URL + 'Category/BatchGroup', { params: apiparams });

    }
}