import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings';
//import { RptdrivergrouponlinehourService } from '../rptdrivergrouponlinehour/rptdrivergrouponlinehour.service';

@Injectable({
    providedIn: 'root'
})

export class RptdrivergrouponlinehourService {

    

    constructor(private http: HttpClient  ) { }

    getonlinehourreport(OnlineHourObj)
    {        
        let apiparams = new HttpParams();

       
        // Begin assigning parameters
        apiparams = apiparams.append('startDate', OnlineHourObj.startDate);
        apiparams = apiparams.append('endDate', OnlineHourObj.endDate);
        apiparams = apiparams.append('driverGroupId', OnlineHourObj.driverGroupId);
        apiparams = apiparams.append('batchGroupId', OnlineHourObj.batchGroupId);
        apiparams = apiparams.append('driverId', OnlineHourObj.driverId);
        apiparams = apiparams.append('Pageindex', OnlineHourObj.pageIndex);
        apiparams = apiparams.append('PageSize', OnlineHourObj.pageSize);
        return this.http.get(AppSettings.API_URL + "Report/OnlineHour", { params: apiparams });

    }

  

}
