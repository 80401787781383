import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(userObj) {
    var userData = "UserName=" + userObj.EmailAddress + "&Password=" + userObj.Password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.http.post(AppSettings.API_URL + 'token', userData, { headers: reqHeader });

    // var response = {
    //   access_token: "Jmq6OjaML8FtaQJ2TbGHuGYAz4iuvwiZwvkzKl2Jj_yl3TET9XMInBKDWFU3G9tPfsPZEYKvCsHHYMTIKrWjoZ2drv97P2-dRJmbsPcaTSdd_yqXorfGXl1Cxr5FUH8FWfIoJobuvmOXW9Yt6ePvANghvI7araq5hOLhc3O8xQhONtDr_L6NqWJjdZVtCRfpmoIuKSYgLRARrioWyKmWuxCoc6s91sH06YnhhaTjrOAKetkNowZiqfUE2I77dwoqSebqBx21zBuRvZvux6TdVEH9QwbUdlSYORx_ttPsNK7d0hiWcoI-NjnopyPcx__nEVgTESIsCDhp4Y3gPfTeYDa8n4YifWX7vlW0ah2cUs4r2LPlmMYViGeOX36TDcsT",
    //   token_type: "bearer",
    //   expires_in: 604799
    // }
    // return new Promise((resolve, reject) => {
    //   resolve(response);
    // });
  }
}
