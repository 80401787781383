import { Component, OnInit, Renderer } from '@angular/core';
import { CategoryService } from '../category.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-batchgroup',
  templateUrl: './batchgroup.component.html',
  styleUrls: ['./batchgroup.component.scss']
})
export class BatchgroupComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  constructor(private router: Router, private categoryService: CategoryService, private renderer: Renderer) { }

  ngOnInit() {
    const that = this.categoryService;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        that.getAllBatchGroup(dataTablesParameters)
          .subscribe((resp: any) => {
            //  that.persons = resp.data;
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [{ data: 'Name', name: 'Batch Group', title: 'Batch Group', orderable: false },
      {
        data: 'Active', name: 'Active', title: 'Status', orderable: false, render: function (data: any) {
          if (Boolean(data)) {
            return "Active";
          }
          else {
            return "Inactive";
          }
        }
      },
      {
        data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
          return '  <a>  <i class="fa fa-pencil" batchgruopId="' + data + '" aria-hidden="true"></i> </a> ';
        }
      }]
    };
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("batchgruopId")) {
        this.router.navigate(["/managebatchgroup/" + event.target.getAttribute("batchgruopId")]);
      }
    });
  }

  AddBatchGroup() {
    this.router.navigate(['/managebatchgroup/0']);
  }

}
