import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageMediaPartyService {



  constructor(private http: HttpClient) { }

  

  getMediaParty(mediapartyId)
  {
      return this.http.get(AppSettings.API_URL + 'Category/MediaParty/' + mediapartyId)
  }

  SaveMediaParty(objMediaParty) {
    
    if (objMediaParty.Id != undefined && objMediaParty.Id != null && objMediaParty.Id > 0) {
    
        return this.http.put(AppSettings.API_URL + 'Category/MediaParty/' + objMediaParty.Id, objMediaParty);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Category/MediaParty', objMediaParty);
    }
  }
}

