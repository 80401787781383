import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageGenreService {



  constructor(private http: HttpClient) { }

  

  getGenre(genreId)
  {
      return this.http.get(AppSettings.API_URL + 'Category/Genre/' + genreId)
  }

  SaveGenre(objGenre) {
    if (objGenre.Id != undefined && objGenre.Id != null && objGenre.Id > 0) {
      return this.http.put(AppSettings.API_URL + 'Category/Genre/' + objGenre.Id, objGenre);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Category/Genre', objGenre);
    }
  }
}

