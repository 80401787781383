import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class BatchgroupService {

  constructor(private http: HttpClient) { }

  getBatchGroup(batchgroupId)
  {
      return this.http.get(AppSettings.API_URL + 'Category/BatchGroup/' + batchgroupId)
  }

  SaveBatchGroup(objBatchGroup) {
    
    if (objBatchGroup.Id != undefined && objBatchGroup.Id != null && objBatchGroup.Id > 0) {
    
        return this.http.put(AppSettings.API_URL + 'Category/BatchGroup/' + objBatchGroup.Id, objBatchGroup);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Category/BatchGroup', objBatchGroup);
    }
  }

}
