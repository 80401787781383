import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ManageGenreService } from './ManageGenre.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { error } from 'util';
import { AppSettings } from '../../../common/AppSettings';
import { NGXToastrService } from '../../../common/toastr.service'
import { debounce } from 'rxjs-compat/operator/debounce';

@Component(
  {
    selector: 'app-manage-genre',
    templateUrl: './ManageGenre.html'
  }
)


export class ManageGenreComponent implements OnInit {

  //GenreObj: any = {};
  GenreId: any;
  submitted = false;

  GenreObj: Genre = {
    Id: 0,
    Name: '',
    Url: null,
    Active: true,
    AssetId: ''
  };

  public formTemplate: FormGroup;



  constructor(private fb: FormBuilder, private router: Router,
    private manageGenreService: ManageGenreService,
    private activatedRoute: ActivatedRoute,
    private ngxToast: NGXToastrService
  ) { }

  ngOnInit() {
    //this.GenreObj.GenreId=null;

    this.formTemplate = this.fb.group(
      {
        Name: ['', Validators.required],
        Active: [false, Validators.required],
        Id: 0,
        Url: [null, Validators.required]
      })

    this.activatedRoute.params.
      subscribe((params: Params) => {
        this.GenreId = parseInt(params['Id']);
      });

    this.getGenre(this.GenreId);
  }

  get f() { return this.formTemplate.controls; }


  getGenre(id) {
    if (id > 0) {
      this.manageGenreService.getGenre(id).subscribe(
        (data: any) => {
          this.GenreObj = data;
          //this.formTemplate.setValue({
          //  Name: this.GenreObj.Name,
          //  Active: this.GenreObj.Active,
          //  Id: this.GenreObj.Id,
          //  Url: this.GenreObj.Url
          //})
        },
        error => console.log(error)
      );
    }

  }


  SaveGenre() {
    this.submitted = true;
    if (this.GenreId > 0) {
      if (this.GenreObj.Url == null || this.GenreObj.Url == undefined) {
        alert('You should upload file.');
        return false;
      }
      else {
        var upload = (<HTMLInputElement>document.getElementById('upload'));
        if (upload != null) {
          if (upload.files.length == 0) {
            this.manageGenreService.SaveGenre(this.GenreObj).subscribe(
              (data: any) => {
                this.ngxToast.typeSuccess("Record saved successfully.");
                this.router.navigate(['/genre']);
              },
              error => console.log(error)
            );
          }
          else {
            this.uploadGenre();
          }
        }
      }
    } else {
      var upload = (<HTMLInputElement>document.getElementById('upload'));
      if (upload != null) {
        if (upload.files.length == 0) {
          return false;
        }
        else {
          this.uploadGenre();
        }
      }
    }
  }

  uploadGenre() {
    var formData = new FormData();
    var filecomp = (<HTMLInputElement>document.getElementById('upload'));
    if (filecomp != null) {
      if ((<HTMLInputElement>document.getElementById('upload')).files.length > 0) {
        var files = (<HTMLInputElement>document.getElementById('upload')).files[0];
        formData.append(files.name, files);
      }
    }

    var token = "bearer " + localStorage.getItem('access_token');

    $.ajax({
      type: "POST",
      url: AppSettings.API_URL + 'Upload/Genre',
      contentType: false,
      processData: false,
      data: formData,
      headers: { "Authorization": token },
      success: function (data) {
      }
    }).then((m) => {
      let assetId: any;
      assetId = m.Entity.Id;

      this.GenreObj.AssetId = assetId;

      this.manageGenreService.SaveGenre(this.GenreObj).subscribe(
        (data: any) => {
          this.ngxToast.typeSuccess("Record saved successfully.");
          this.router.navigate(['/genre']);
        },
        error => console.log(error)
      );
    });
  }

  GoBackToGenre() {
    this.router.navigate(['/genre']);
  }
}

export class Genre {
  Id: number;
  Name: string;
  Active: boolean;
  Url: string;
  AssetId: string;
}


