import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class ManageDriverService {

  constructor(private http: HttpClient) { }

  getDriver(driverId) {
    return this.http.get(AppSettings.API_URL + 'Driver/' + driverId);
  }

  SaveDriver(objDriver) {
    if (objDriver.Id != null && objDriver.Id > 0) {
      return this.http.put(AppSettings.API_URL + 'Driver/' + objDriver.Id, objDriver);
    }
    else {
      return this.http.post(AppSettings.API_URL + 'Driver', objDriver);
    }
  }
}
