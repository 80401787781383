import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { RptdrivergrouponlinehourService } from '../rptdrivergrouponlinehour/rptdrivergrouponlinehour.service';
import { DriverGroupService } from '../../driver-group/driver-group.service';
import { DatePipe } from '@angular/common';
import { CategoryService } from 'src/app/category/category.service';
import { DriverService } from 'src/app/driver/driver.service';

import * as _moment from 'moment';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { Router } from '@angular/router';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD MMM YYYY HH:mm',
    fullPickerInput: 'DD MMM YYYY HH:mm',
    datePickerInput: 'DD MMM YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'DD MMMM YYYY',


    // parseInput: 'l LT',
    // fullPickerInput: 'l LT',
    // datePickerInput: 'l',
    // timePickerInput: 'LT',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'LL',
    // monthYearA11yLabel: 'MMMM YYYY',
};


@Component({
    selector: 'app-rptdrivergrouponlinehour',
    templateUrl: './rptdrivergrouponlinehour.component.html',
    styleUrls: ['./rptdrivergrouponlinehour.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        // `MomentDateTimeAdapter` can be automatically provided by importing
        // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    ],
})
export class RptdrivergrouponlinehourComponent implements OnInit {

    drivegrouplistobjcombo: any;
    drivegrouplistobjcomboselected: any;
    startdate = new moment();
    enddate = new moment();
    firsttime: boolean;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    batchGroupList: any;
    batchGroupId: any;
    driverList: any;
    driverId: any;

    dropdownSettings = {
        singleSelection: true,
        idField: 'Id',
        textField: 'Name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
    };

    DriverdropdownSettings = {
        singleSelection: true,
        idField: 'Id',
        textField: 'FullName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
    };

    constructor(
        private drivergroupservice: RptdrivergrouponlinehourService,
        private drivergroupserviceforcombo: DriverGroupService,
        private datePipe: DatePipe,
        private categoryService: CategoryService,
        private driverService: DriverService,
        private router: Router
    ) { }

    ngOnInit() {
        this.startdate = new Date(new Date().setMonth(new Date().getMonth() - 1));
        this.enddate = new Date();
        this.getdrivegroups();
        this.getBatchGroup();
        this.getDrivers();
        const that = this.drivergroupservice;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: false,
            searching: false,
            destroy: true,
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
                dataTablesParameters.pageSize = dataTablesParameters.length;
                if (this.drivegrouplistobjcomboselected != null && this.drivegrouplistobjcomboselected.length > 0) {
                    dataTablesParameters.driverGroupId = this.drivegrouplistobjcomboselected[0].Id;
                }
                if (this.batchGroupId != null && this.batchGroupId.length > 0) {
                    dataTablesParameters.batchGroupId = this.batchGroupId[0].Id;
                }
                if (this.driverId != null && this.driverId.length > 0) {
                    dataTablesParameters.driverId = this.driverId[0].Id;
                }
                dataTablesParameters.startDate = this.datePipe.transform(this.startdate, 'dd MMM yyyy');
                dataTablesParameters.endDate = this.datePipe.transform(this.enddate, 'dd MMM yyyy');
                that.getonlinehourreport(dataTablesParameters)
                    .subscribe((resp: any) => {
                        callback({
                            recordsTotal: resp.TotalRecords,
                            recordsFiltered: resp.TotalRecords,
                            data: resp.List
                        });
                    });

            },
            columns: [
                { data: 'Driver.DriverGroup.Name', name: 'DriverGroup', title: 'DriverGroup', orderable: false },
                { data: 'Driver.FullName', name: 'Name', title: 'Name', orderable: false },
                { data: 'Driver.VehiclePlateNumber', name: 'Vehicle', title: 'Vehicle', orderable: false },
                { data: 'Driver.Device.UniqueId', name: 'DeviceID', title: 'Device Id', orderable: false },
                {
                    data: "TotalSeconds",
                    title: " Total Time ",
                    orderable: false,
                    render: function (data, type, row, meta) {

                        var hrs = Math.floor(data / 3600);
                        var min = Math.floor((data - (hrs * 3600)) / 60);
                        var seconds = data - (hrs * 3600) - (min * 60);
                        seconds = Math.round(seconds * 100) / 100

                        var result = (hrs < 10 ? "0" + hrs : hrs) + ' Hours ';
                        result += "-" + (min < 10 ? "0" + min : min) + ' Mins ';
                        result += "-" + (seconds < 10 ? "0" + seconds : seconds) + ' Sec ';
                        return result;
                    }
                }
            ],
            dom: 'Bfrtip',
            // Configure the buttons
            buttons: [
                {
                    extend: 'csv',
                    text: 'Export to CSV'
                }
            ]
        };


        //this.dtOptions = {
        //    pagingType: 'full_numbers',
        //    pageLength: 10,
        //    serverSide: true,
        //    processing: false,
        //    searching: false,
        //    ajax: (dataTablesParameters: any, callback) => {
        //        this.firsttime = false;            
        //        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        //        dataTablesParameters.pageSize = dataTablesParameters.length;
        //        dataTablesParameters.driverGroupId = 12;
        //        dataTablesParameters.startDate = '01 Dec 2018';//this.datePipe.transform(this.startdate, 'dd MMM yyyy');
        //        dataTablesParameters.endDate = this.datePipe.transform(this.enddate, 'dd MMM yyyy');
        //        console.log(dataTablesParameters);
        //        that.getonlinehourreport(dataTablesParameters)
        //            .subscribe((resp: any) => {
        //                console.log(resp);
        //                callback({
        //                    recordsTotal: resp.TotalRecords,
        //                    recordsFiltered: resp.TotalRecords,
        //                    data: resp.List
        //                });
        //            }, error => console.log(error)
        //            );

        //    },
        //    columns: [
        //        { data: 'Driver.FullName', name: 'Name', title: 'Name', orderable: false },
        //        { data: 'Driver.VehiclePlateNumber', name: 'Vehicle', title: 'Vehicle', orderable: false },
        //        { data: 'Driver.Device.UniqueId', name: 'DeviceID', title: 'Device Id', orderable: false },
        //        {
        //            data: "TotalSeconds",
        //            render: function (data, type, row, meta) {

        //                var hrs = Math.floor(data / 3600);
        //                var min = Math.floor((data - (hrs * 3600)) / 60);
        //                var seconds = data - (hrs * 3600) - (min * 60);
        //                seconds = Math.round(seconds * 100) / 100

        //                var result = (hrs < 10 ? "0" + hrs : hrs) + ' Hours ';
        //                result += "-" + (min < 10 ? "0" + min : min) + ' Mins ';
        //                result += "-" + (seconds < 10 ? "0" + seconds : seconds) + ' Sec ';
        //                return result;                    
        //            }
        //        }            
        //    ]
        //};


    }

    reloadGrid() {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    getdrivegroups() {
        var driverGroupObjCombo = {
            pageIndex: 1, pageSize: 1000
        };
        this.drivergroupserviceforcombo.getAllDriverGroup(driverGroupObjCombo).subscribe(
            (data: any) => {
                this.drivegrouplistobjcombo = data.List;
            }, error => console.log(error));
    }


    getBatchGroup() {
        var driverGroupObjCombo = {
            pageIndex: 1, pageSize: 1000
        };
        this.categoryService.getAllBatchGroup(driverGroupObjCombo).subscribe(
            (data: any) => {
                this.batchGroupList = data.List;
            }, error => console.log(error));
    }

    getDrivers() {
        var driverGroupObjCombo = {
            name: '',
            email: '',
            phoneNumber: '',
            driverGroupId: -1,
            pageIndex: 1,
            pageSize: 1000
        };
        this.driverService.getAllDriver(driverGroupObjCombo).subscribe(
            (data: any) => {
                this.driverList = data.List;
            }, error => console.log(error));
    }

}
