import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { LayoutComponent } from './layout/layout.component';
import { ManageAdvertisementComponent } from './advertisement-list/ManageAdvertisement/ManageAdvertisement.component'
import { DriverComponent } from './driver/driver.component';
import { ManageDriverComponent } from './driver/ManageDriver/ManageDriver.component';
import { DriverGroupComponent } from './driver-group/driver-group.component';
import { ManageDriverGroupComponent } from './driver-group/ManageDriverGroup/ManageDriverGroup.component';
import { GenreComponent } from './category/Genre/genre.component';
import { ManageGenreComponent } from './category/Genre/ManageGenre/ManageGenre.component';
import { DriverGroupPlayListComponent } from './driver-group/DriverGroupPlayList/DriverGroupPlayList.component';
import { MediaPartyComponent } from './category/MediaParty/mediaparty.component'
import { ManageMediaPartyComponent } from './category/MediaParty/ManageMediaParty/ManageMediaParty.component';

// Advertiser Component 
import { AdvertiserListcomponent } from './advertiser/advertiser.component';
import { ManageadvertiserComponent } from './advertiser/manageadvertiser/manageadvertiser.component';

// Inventory Device Component
import { DevicesComponent } from './inventory/devices/devices.component';
import { ManagedevicesComponent } from './inventory/devices/managedevices/managedevices.component';
import { UserProfileComponent } from './UserProfile/userProfile.component';

//Report 
import { RptdrivergrouponlinehourComponent } from './Reports/rptdrivergrouponlinehour/rptdrivergrouponlinehour.component';
import { BatchgroupComponent } from './category/batchgroup/batchgroup.component';
import { ManagebatchgroupComponent } from './category/batchgroup/managebatchgroup/managebatchgroup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GamesQuestionComponent } from './games-question/games-question.component';
import { GamesAnswerComponent } from './games-answer/games-answer.component';
import { GamesQuestionManageComponent } from './games-question-manage/games-question-manage.component';
import { GamesAnswerManageComponent } from './games-answer-manage/games-answer-manage.component';
import { GamesPrizeComponent } from './games-prize/games-prize.component';
import { GamesPrizeManageComponent } from './games-prize-manage/games-prize-manage.component';

import { AdvertisementListComponent } from './advertisement-list/advertisement-list.component';
import { GamesWinnerComponent } from './games-winner/games-winner.component';
import { GamesMusicComponent } from './games-music/games-music.component';
import { GamesComponent } from './games/games.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'advertisement', component: AdvertisementListComponent },
      { path: 'manageadvertisement/:Id', component: ManageAdvertisementComponent },
      { path: 'driver', component: DriverComponent },
      { path: 'managedriver/:Id', component: ManageDriverComponent },
      { path: 'drivergroup', component: DriverGroupComponent },
      { path: 'managedrivergroup/:Id', component: ManageDriverGroupComponent },
      { path: 'batchgroup', component: BatchgroupComponent },
      { path: 'managebatchgroup/:Id', component: ManagebatchgroupComponent },

      { path: 'genre', component: GenreComponent },
      { path: 'managegenre/:Id', component: ManageGenreComponent },

      { path: 'mediaparty', component: MediaPartyComponent },
      { path: 'managemediaparty/:Id', component: ManageMediaPartyComponent },
      { path: 'advertiser', component: AdvertiserListcomponent },
      { path: 'manageadvertiser/:Id', component: ManageadvertiserComponent },
      { path: 'device', component: DevicesComponent },
      { path: 'managedevice/:Id', component: ManagedevicesComponent },
      { path: 'userprofile', component: UserProfileComponent },
      { path: "rptonlinehours", component: RptdrivergrouponlinehourComponent },

      { path: "games", component: GamesComponent },
      { path: "gamesquestion", component: GamesQuestionComponent },
      { path: "gamesquestionmanage/:Id", component: GamesQuestionManageComponent },
      { path: "gamesanswer", component: GamesAnswerComponent },
      { path: "gamesanswermanage/:Id", component: GamesAnswerManageComponent },
      { path: "gamesprize", component: GamesPrizeComponent },
      { path: "gamesprizemanage/:Id", component: GamesPrizeManageComponent },
      { path: "gamesaudio", component: GamesMusicComponent },
      { path: "gameswinner", component: GamesWinnerComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.UnAuthorizedRequestRedirect();
  }

  UnAuthorizedRequestRedirect() {
    var accesstoken = localStorage.getItem('access_token');
    if (accesstoken != null) {

    }
    else {
      this.router.navigate(['/login']);
    }
  }
}
