import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from './userProfile.service';
import * as $ from "jquery";

@Component({
    selector: 'app-userprofile',
    templateUrl: './userProfile.html'    
})
export class UserProfileComponent implements OnInit {
    result: any;
    UserProfileData: any;
    UserProfileObj: { [k: string]: any } = {};
    constructor(private router: Router, private userprofileService: UserProfileService) { }

    ngOnInit() {
        this.getUserProfile();
    }

    getUserProfile() {

        this.userprofileService.getUserProfile().subscribe(
            (data: any) => {
                this.UserProfileData = data;
            },
            error => console.log(error)
        );
    }

    SaveUserProfile(form) {
        if (form.valid) {
            this.userprofileService.ManageUserProfile(this.UserProfileObj).subscribe(
                (data: any) => {
                    this.router.navigate(['/userprofile']);
                },
                error => console.log(error)
            );
        }
    }

}

