import { Component, OnInit, NgModule, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../category.service';
import * as $ from "jquery";
import { HttpClient, HttpResponse } from '@angular/common/http';


@Component(
  {
    selector: 'app-genre',
    templateUrl: './genre-list.html',
    styleUrls: ['../../app.component.scss']
  }
)


export class GenreComponent implements OnInit {
  dtOptions: any = {};
  result: any;
  GenreList: any;

  constructor(private http: HttpClient, private router: Router, private categoryService: CategoryService, private renderer: Renderer, ) { }

  ngOnInit() {
    // this.getAllGenres();


    const that = this.categoryService;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: false,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.pageIndex = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        dataTablesParameters.pageSize = dataTablesParameters.length;
        that.getAllGenres(dataTablesParameters)
          .subscribe((resp: any) => {
            //  that.persons = resp.data;
            callback({
              recordsTotal: resp.TotalRecords,
              recordsFiltered: resp.TotalRecords,
              data: resp.List
            });
          });
      },
      columns: [
        { data: 'Name', name: 'INamed', title: 'Name', orderable: false },
        {
          data: 'Active', name: 'Active', title: 'Status', orderable: false, render: function (data: any) {
            if (Boolean(data)) {
              return "Active";
            }
            else {
              return "Inactive";
            }
          }
        },
        {
          data: 'Id', title: 'Action', orderable: false, render: function (data: any, type: any, full: any) {
            return '  <a>  <i class="fa fa-pencil" GenreId="' + data + '" aria-hidden="true"></i> </a> ';
          }
        }]
    };
  }


  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("GenreId")) {
        this.router.navigate(["/managegenre/" + event.target.getAttribute("GenreId")]);
      }
    });
  }

  getAllGenres() {
    var driverObj = { pageIndex: 1, pageSize: 20 };
    this.categoryService.getAllGenres(driverObj).subscribe(
      (data: any) => {
        this.GenreList = data.List;
        // this.BindAdvertisementGrid();
      },
      error => console.log(error)
    );
  }


  AddGenre() {
    this.router.navigate(['/managegenre/0']);
  }


}
