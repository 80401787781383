import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../common/AppSettings'

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {

  constructor(private http: HttpClient) { }

  getAllAdvertiser(AdvertiserObj) {
    var AdvertiserData = "?pageIndex=" + AdvertiserObj.pageIndex + "&pageSize=" + AdvertiserObj.pageSize;
    return this.http.get(AppSettings.API_URL + 'Advertiser' + AdvertiserData);
  }
}
