import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ManageMediaPartyService } from './ManageMediaparty.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { error } from 'util';
import { NGXToastrService } from '../../../common/toastr.service'

@Component(
    {
        selector: 'app-manage-mediaparty',
        templateUrl: './ManageMediaParty.html'
    }
)


export class ManageMediaPartyComponent implements OnInit {

    MediaPartyObj: any = {};
    MediaPartyId: any;
    submitted = false;

    public formTemplate: FormGroup;



    constructor(
        private fb: FormBuilder, private router: Router,
        private manageMediaPartyService: ManageMediaPartyService,
        private activatedRoute: ActivatedRoute,
        private ngxToast: NGXToastrService
    ) { }

    ngOnInit() {

        this.formTemplate = this.fb.group(
            {
                Name: ['', Validators.required],
                Active: [true, Validators.required],
                Id: 0
            })

        this.activatedRoute.params.
            subscribe((params: Params) => {
                this.MediaPartyId = parseInt(params['Id']);
            });

        this.getMediaParty(this.MediaPartyId);
    }

    get f() { return this.formTemplate.controls; }


    getMediaParty(id) {
        if (id > 0) {
            this.manageMediaPartyService.getMediaParty(id).subscribe(
                (data: any) => {
                    this.MediaPartyObj = data;
                    this.formTemplate.setValue({
                        Name: this.MediaPartyObj.Name,
                        Active: this.MediaPartyObj.Active,
                        Id: this.MediaPartyObj.Id
                    })
                },
                error => console.log(error)
            );
        }

    }


    SaveMediaParty() {

        this.submitted = true;
        if (this.formTemplate.valid) {
            this.manageMediaPartyService.SaveMediaParty(this.formTemplate.value).subscribe(
                (data: any) => {
                    this.ngxToast.typeSuccess("Record saved successfully.");
                    this.router.navigate(['/mediaparty']);
                },
                error => console.log(error)
            );
        }
    }

    GoBackToMediaParty() {
        this.router.navigate(['/mediaparty']);
    }


}


